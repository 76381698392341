import { forwardRef, useEffect } from 'react';
import { RakeLayout } from '@meitu/rake';
import { useHistory, useLocation } from 'react-router-dom';
import { useStoreState } from '@/store';
import _ from 'lodash';
import {useChannelRoute} from "@/routes/channel";
import {useGetRouteByName} from "@/hooks/router";

export { default as Activate } from './Activate';

export default forwardRef<HTMLDivElement>(function Channel(props, ref) {
  const authMenuTreeFirstRoute = useStoreState(
      (state) => state.account.authMenuTreeFirstRoute
  );
  const channelRoute = useChannelRoute();
  const shouldRedirect = !_.size(channelRoute?.children);
  const getRouteByName = useGetRouteByName();
  const history = useHistory();

  useEffect(
      () => {
        shouldRedirect && history.replace(getRouteByName(authMenuTreeFirstRoute));
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [shouldRedirect]
  );

  return <RakeLayout.RoutesContent ref={ref} routeItem={channelRoute} />;
});
