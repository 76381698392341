import hash from 'hash-it';
import _ from 'lodash';

import {GroupLabel} from "@/types";

export * from './account';
export * from './download';
export * from './extremumTable';
export * from './menu';

export function generateHash(record: any) {
    return hash(record);
}


/**
 * 生成渠道标签名称
 */
export const generateLabelNames = (labels: GroupLabel[] | undefined) =>
    _.isEmpty(labels) ? '未分组' : _.map(labels, 'name').join('、');