import { FadeTransition, RakeLayout } from '@meitu/rake';
import { Route, Switch } from 'react-router-dom';
import { useAppTransitionKey } from '@/hooks';
import { useRef } from 'react';
import routes from '@/routes';
import Header from './Header';

export function MainContainer() {
  const nodeRef = useRef<HTMLDivElement>(null);
  const transitionKey = useAppTransitionKey();
  return (
    <RakeLayout.MainContainer header={<Header />}>
      <FadeTransition transitionKey={transitionKey} nodeRef={nodeRef}>
        <Switch>
          {routes.map(({ path: routePath, component: Component }) => {
            if (!Component) {
              console.warn('路由组件缺失', routePath);
              return null;
            }
            return (
              <Route key={routePath} path={routePath}>
                <Component ref={nodeRef} />
              </Route>
            );
          })}
        </Switch>
      </FadeTransition>
    </RakeLayout.MainContainer>
  );
}
