import {CommonExportButton} from '@/components';

import {useStoreState} from '@/store';
import {getScriptExportParamsFromUrl} from '@/utils';
import {EXPORT_SCRIPT_URL} from "@/api/talent/script";

/**
 * 渠道管理导出按钮+弹窗
 */
interface ScriptExportProps {
  ids?: number[];
}

export default function ManuscriptExport({ ids }: ScriptExportProps) {
  const total = useStoreState((state) => state.talent.scriptKoc.total);
  return total > 0 ? (
    <CommonExportButton
      url={EXPORT_SCRIPT_URL}
      params={getScriptExportParamsFromUrl.bind(null)}
      title="导出稿件"
    />
  ) : null;
}
