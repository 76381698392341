import Groups, {Labels} from '@/containers/Talent/Groups';
import {RouteItem} from '@meitu/rake';

/**
 * 一级菜单路由
 */
const FIRST_MENU_PATH = 'talent';

/**
 * 二级菜单路由
 * NOTE: RakeLayout.RoutesContent 组件除顶级路由外，path 均不需要加’/‘。
 */
const manuscriptGroupsRoute: RouteItem = {
    path: 'groups',
    title: '分组管理',
    component: Groups,
    children: [
        {
            path: 'labels',
            title: '标签',
            component: Labels
        }
    ]
};


/**
 * 补充完整 path。用于定义独立的 RakeLayout.RoutesContent routeItem
 */
export const manuscriptGroupsRouteItem = {
    ...manuscriptGroupsRoute,
    path: `/${FIRST_MENU_PATH}/${manuscriptGroupsRoute.path}`
};

/* 限制在指定项目下加载路由 */
export function useManuscriptGroupsRoute() {
    /* 限制在指定项目下加载路由 */
    return {
        ...manuscriptGroupsRouteItem,
        path: `/${FIRST_MENU_PATH}/${manuscriptGroupsRouteItem.path}`,
        children: manuscriptGroupsRouteItem.children
    }
}

/**
 * 补充完整 path。用于定义选项卡内容
 */
// @ts-ignore
export const manuscriptGroupsTabsPanes = manuscriptGroupsRoute.children.map(
    ({path, title}) => ({
        path: `/${FIRST_MENU_PATH}/${manuscriptGroupsRoute.path}/${path}`,
        title
    })
);
export default manuscriptGroupsRoute;
