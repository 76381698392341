import {
  FetchableSelect,
  FetchableSelectProps,
  FetchableSelectRef,
  useWatch
} from '@meitu/rake';

import { useMemo, useRef } from 'react';
import { useStoreActions, useStoreState } from '@/store';

import { MANUSCRIPTS_GROUPS_MAP } from '@/constants/talent/manuscripts';
import { ManuscriptsGroupsType } from '@/types/talent/scripts';

interface ManuscriptsGroupsSelectProps<ValueType>
  extends Omit<FetchableSelectProps<ValueType>, 'onFetch' | 'options'> {
  /** 关联类型 */
  groupsType: ManuscriptsGroupsType;
  /** 切换应用后重置选中数据值 */
  resetValue?: ValueType;
  filterZero?: boolean;
}

/**
 * 代理商/标签下拉选择器
 */
export default function ManuscriptsGroupsSelect<ValueType = number | number[]>({
  groupsType,
  resetValue,
  filterZero=false,
  ...restProps
}: ManuscriptsGroupsSelectProps<ValueType>) {
  const fetchableSelectRef = useRef<FetchableSelectRef<ValueType>>(null);

  const fetchGroupsOptions = useStoreActions(
    (actions) => actions.talent.scriptKoc.fetchGroupsOptions
  );
  const groupsOptionsMap = useStoreState(
    (state) => state.talent.scriptKoc.groupsOptionsMap
  );

  const list = useMemo(
    () => groupsOptionsMap?.[groupsType] ?? [],
    [groupsOptionsMap, groupsType]
  );

  const options = useMemo<FetchableSelectProps<ValueType>['options']>(() => {
    const options = list.filter((option)=>{
      return !filterZero || option.id != 0
    }).map(({ id, name }) => ({
      value: id,
      label: name
    }));
    return options;
  }, [list]);

  /** 应用变化、类型变化时刷新下拉列表 */
  useWatch([groupsType], (prev) => {
    fetchableSelectRef?.current?.resetOptions(resetValue);
  });

  const label = MANUSCRIPTS_GROUPS_MAP.get(groupsType);

  return (
    <FetchableSelect
      ref={fetchableSelectRef}
      testKey={label}
      placeholder={`请选择${label}`}
      allOptionLabel={`全部${label}`}
      options={options}
      onFetch={() => fetchGroupsOptions({ groupsType })}
      {...restProps}
    />
  );
}
