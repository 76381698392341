import {
    getIndicatorModalOptions,
    getIndicatorSelectOptions, ManuscriptsDimensionOptionMap, ManuscriptsDimensionType,
} from '@/types';
import {ALL_OPTION_VALUE, ALL_OPTION_VALUE_STRING} from '@/constants';

import {
    ScriptsFilterValues
} from '@/types/talent/scripts';
/**
 * 稿件互动明细：列表自定义展示（表头配置）
 * 自定义展示：约稿时间、Gap Day、稿件审核链接、内容形式、达人名称、达人微信名称、uid、供应商
 */
export const SCRIPT_KOC_LIST_COLUMN_KEYS = [
    ManuscriptsDimensionType.AgreedTimeStr,
    ManuscriptsDimensionType.MediaTypeDesc,
    ManuscriptsDimensionType.ContentTypeDesc,
    ManuscriptsDimensionType.ExpertWechatName,
    ManuscriptsDimensionType.ExpertUid,
    ManuscriptsDimensionType.Supplier,
    ManuscriptsDimensionType.Labels,
    ManuscriptsDimensionType.CREATE_USER,
    ManuscriptsDimensionType.APP
];

export const SCRIPT_KOC_LIST_COLUMN_OPTIONS = getIndicatorModalOptions(
    SCRIPT_KOC_LIST_COLUMN_KEYS,
    ManuscriptsDimensionOptionMap
);

/**
 * 稿件互动明细——复数形式
 */
export enum ManuscriptsGroupsType {
    /** 应用 */
    Apps = 'apps',
    /** 媒体类型 */
    MediaTypes = 'mediaTypes',
    /** 内容类型 */
    ContentTypes = 'contentTypes',
    /** 渠道标签。允许一对多关系 */
    Labels = 'labels',
}

/** 稿件互动明细——复数形式 */
export const MANUSCRIPTS_GROUPS_MAP = new Map<ManuscriptsGroupsType, string>([
    [ManuscriptsGroupsType.Apps, '应用'],
    [ManuscriptsGroupsType.MediaTypes, '媒体类型'],
    [ManuscriptsGroupsType.ContentTypes, '内容类型'],
    [ManuscriptsGroupsType.Labels, '标签'],
]);


/**
 * 稿件互动明细-参数默认值
 */
export const SCRIPT_KOC_FILTER_DEFAULT_VALUES: ScriptsFilterValues =
    Object.freeze({
        appIds: [ALL_OPTION_VALUE],
        mediaTypeIds: [ALL_OPTION_VALUE_STRING],
        contentTypeIds: [ALL_OPTION_VALUE_STRING],
        labelIds: [ALL_OPTION_VALUE],
    });
