import {ApartmentOutlined} from '@ant-design/icons';
import manuscriptGroupsRoute from './groups';
import {RouteItem} from '@meitu/rake';
import settingManuscriptRoute from './manuscript';
import Talent from "@/containers/Talent";

const talentRoute: RouteItem = {
    path: '/talent',
    title: '稿件管理',
    icon: <ApartmentOutlined/>,
    component: Talent,

    children: [
        settingManuscriptRoute,
        manuscriptGroupsRoute
    ]
};


export default talentRoute;
