import {Form, Button, Typography} from 'antd';
import {UserOutlined, LockOutlined, RobotOutlined} from '@ant-design/icons';
import {TrimInput} from '@meitu/rake';

import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';
import {login, LOGIN_VERIFY_CODE_URL} from '@/api';

import {LoginFormValues} from '@/types';
import {
    PASSWORD_REGEX,
    PASSWORD_REGEX_MSG,
    VALID_CODE_REGEX,
    VALID_CODE_REGEX_MSG
} from '@/constants';
import {EMAIL_REGEX} from '@meitu/util';

import styles from './LoginForm.module.less';

export default function LoginForm(props: { onLoginChecked: (account: string) => void; }) {
    const history = useHistory();
    const [form] = Form.useForm<LoginFormValues>();
    const [loginLoading, setLoginLoading] = useState<boolean>(false);
    const [verifyCodeVersion, setVerifyCodeSrc] = useState<string>();

    const onFinish = (values: LoginFormValues) => {
        setLoginLoading(true);
        login(values)
            .then(({data: loginSign}) => {
                setLoginLoading(false);
                if (loginSign === 1) {
                    //真正登录成功
                    history.replace('/');
                } else {
                    //登录成功，跳转邮箱验证
                    props.onLoginChecked(values.account);
                }
            })
            .catch(() => {
                fetchVerifyCode();
                setLoginLoading(false);
            });
    };

    const fetchVerifyCode = () => {
        form.setFieldsValue({code: ''});
        setVerifyCodeSrc(_.uniqueId('vc'));
    };

    useEffect(() => {
        fetchVerifyCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Form form={form} size="large" autoComplete="off" onFinish={onFinish}>
                <Form.Item
                    name="account"
                    validateTrigger="onBlur"
                    rules={[
                        {pattern: EMAIL_REGEX, message: '请填写正确的登录账号'},
                        {required: true, message: '请输入登录账号'}
                    ]}
                >
                    <TrimInput
                        prefix={<UserOutlined/>}
                        maxLength={64}
                        placeholder="请输入登录账号"
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    validateTrigger="onBlur"
                    rules={[
                        {
                            validator(rule, value) {
                                if (!value) {
                                    rule.message = '请输入登录密码';
                                    return Promise.reject();
                                }
                                if (value.length < 8 || value.length > 16) {
                                    rule.message = '输入8-16位密码';
                                    return Promise.reject();
                                }
                                if (!value.match(PASSWORD_REGEX)) {
                                    rule.message = PASSWORD_REGEX_MSG;
                                    return Promise.reject();
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}
                >
                    <TrimInput
                        inputType="password"
                        prefix={<LockOutlined/>}
                        placeholder="请输入登录密码"
                    />
                </Form.Item>
                <Form.Item className={styles.verifyCode}>
                    <Form.Item
                        noStyle
                        name="code"
                        validateTrigger="onBlur"
                        rules={[
                            {
                                pattern: VALID_CODE_REGEX,
                                message: VALID_CODE_REGEX_MSG
                            },
                            {required: true, message: '请输入验证码'}
                        ]}
                    >
                        <TrimInput
                            prefix={<RobotOutlined/>}
                            className={styles.verifyCodeInput}
                            placeholder="请输入验证码"
                        />
                    </Form.Item>
                    <img
                        className={styles.verifyCodeImg}
                        src={`${LOGIN_VERIFY_CODE_URL}?v=${verifyCodeVersion}`}
                        alt="刷新验证码"
                        onClick={fetchVerifyCode}
                    />
                </Form.Item>
                <Form.Item noStyle>
                    <Button type="primary" block htmlType="submit" loading={loginLoading}>
                        登录
                    </Button>
                    <Typography.Text type="warning">
                        tips：首次访问该系统需联系您的对接人进行权限开通
                    </Typography.Text>
                </Form.Item>
            </Form>
        </div>
    );
}
