import { AppItem } from './app';
import { ChannelTypeListItem } from './channel';

/**
 * 登录表单
 */
export interface LoginFormValues {
  /** 账号（邮箱） */
  account: string;
  /** 密码 */
  password: string;
  /** 验证码 */
  code: string;
}

/**
 * 登录表单
 */
export interface LoginFormEmailCodeValues {
  /** 邮箱验证码 */
  code: string;
}

/**
 * 账户信息
 */
export interface AccountInfo {
  /** ID */
  id: number;
  /** 邮箱 */
  email: string;
  /** 账户名 */
  username: string;
  /** 拥有的app列表 */
  apps: AppItem[];
  /** 可选投放平台列表 */
  types: ChannelTypeListItem[];

  appRoles: UserAppRolesItem[];

}

/**
 * 用户应用权限信息（基础）
 */
export interface UserAppRolesItem {
  /** 投放方式 */
  adType: string;
  /** 应用id */
  appId: number;
  /** 应用+平台 id */
  appInfoId: number;
  /** 角色 id */
  roleId: number;
  /** 标签 id */
  limitIds?: number[];

  supplierId:number;

  appIds?:number[];

}


/**
 * 投放方式
 */
export enum AdType {
  /** 渠道投放 */
  CHANNEL = 'channel',

  /** 达人投放 */
  TALENT = 'talent'
}

export const AD_TYPE_MAP = new Map<string, string>([
  [AdType.CHANNEL, '渠道投放'],
  [AdType.TALENT, '达人投放']
]);

export const AD_TYPE_LIST = [AdType.CHANNEL, AdType.TALENT];
