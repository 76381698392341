import { action, thunk } from 'easy-peasy';
import { ActivateStoreModel } from '@/types';
import { getActivateOverviewList } from '@/api';

const activate: ActivateStoreModel = {
  /**
   * 激活概况查询条件
   */
  overviewFilters: undefined,

  setOverviewFilters: action((state, overviewFilters) => {
    state.overviewFilters = overviewFilters;
  }),

  /**
   * 激活概况列表
   */
  overviewList: undefined,

  setOverviewList: action((state, overviewList) => {
    state.overviewList = overviewList;
  }),

  fetchOverviewList: thunk(async (actions, payload) => {
    actions.setOverviewFilters(payload);
    if (!payload) return Promise.reject();
    try {
      const list = await getActivateOverviewList(payload);
      actions.setOverviewSummary(list?.[0]);
      const overviewList = list?.slice(1);
      actions.setOverviewList(overviewList);
      return overviewList;
    } catch (error) {
      actions.setOverviewSummary(undefined);
      actions.setOverviewList(undefined);
      return Promise.reject(error);
    }
  }),

  /**
   * 激活概况汇总数据
   */
  overviewSummary: undefined,

  setOverviewSummary: action((state, overviewSummary) => {
    state.overviewSummary = overviewSummary;
  })
};

export default activate;
