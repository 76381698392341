import {Card} from 'antd';
import {RakeTable, RakeTableProps} from '@meitu/rake';
import {ExportAction} from './ActivateExportAction';

import {useStoreState} from '@/store';
import {addSummaryColumn} from '@/utils';

import {ActivateItem} from '@/types';

interface ActivateListProps extends RakeTableProps<ActivateItem> {
}

export default function ActivateList({
                                         ...restRakeTableProps
                                     }: ActivateListProps) {
    const overviewSummary = useStoreState(
        (state) => state.activate.overviewSummary
    );

    const columns: RakeTableProps<ActivateItem>['columns'] = [
        addSummaryColumn(
            {
                dataIndex: 'tag',
                title: ' 渠道标识',
                align: 'center'
            },
            '汇总'
        ),
        addSummaryColumn(
            {
                dataIndex: 'typeDesc',
                title: ' 投放平台',
                align: 'center'
            },
            '-'
        ),
        addSummaryColumn(
            {
                dataIndex: 'totalNew',
                title: '总新增',
                align: 'center'

            },
            overviewSummary?.totalNew
                ? overviewSummary?.totalNew
                : '-'
        ),
        addSummaryColumn(
            {
                dataIndex: 'incomeUv',
                title: '付费用户数',
                align: 'center',
            },
            overviewSummary?.incomeUv
                ? overviewSummary?.incomeUv
                : '-'
        )
    ];

    return (
        <Card title="数据明细" bordered={false} extra={<ExportAction/>}>
            <RakeTable<ActivateItem>
                columns={columns}
                size="small"
                rowKey="id"
                pagination={{pageSizeOptions: ['20', '100', '200']}}
                {...restRakeTableProps}
            />
        </Card>
    );
}
