import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Switch, Route } from 'react-router-dom';

import { ConfigProvider } from 'antd';
import { StoreProvider } from 'easy-peasy';
import { RakeConfigProvider } from '@meitu/rake';
import AppEntry from '@/AppEntry';
import Login from '@/containers/Login';

import store from './store';
import reportWebVitals from './reportWebVitals';

import { HOME_ROUTE } from '@/constants';
import { LOGIN_ROUTE } from '@/constants';

import './index.less';

// 语言环境设置
import locale from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

ReactDOM.render(
  <StoreProvider store={store}>
    <ConfigProvider locale={locale}>
      <RakeConfigProvider>
        <BrowserRouter>
          <Switch>
            <Route path={LOGIN_ROUTE}>
              <Login />
            </Route>
            <Route path="/" exact>
              <Redirect to={`${HOME_ROUTE}${window.location.search}`} />
            </Route>
            <Route path="*">
              <AppEntry />
            </Route>
          </Switch>
        </BrowserRouter>
      </RakeConfigProvider>
    </ConfigProvider>
  </StoreProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
