import { Col, Form, Select } from 'antd';
import { DateRangePicker, FilterForm, FilterFormProps, MultiSelect } from '@meitu/rake';
import ChannelTagSelect from './ChannelTagSelect';

import { useEffect, useState } from 'react';
import { useStoreState } from '@/store';

import { ActivateFilterValues } from '@/types';
import moment, { Moment } from 'moment';
import _ from 'lodash';

const today = moment().subtract(0, 'day');
const lastMonth = moment().subtract(30, 'day');

function disabledDate(current: Moment) {
  return current < lastMonth || current >= today;
}

const COL_PROPS = { span: 6 };

interface ActivateFormProps
  extends Omit<FilterFormProps<ActivateFilterValues>, 'children'> {
}

export default function ActivateForm({
                                       formValues,
                                       ...restFilterFormProps
                                     }: ActivateFormProps) {
  const apps = useStoreState((states) => states.account.apps);
  const appOptions = useStoreState((states) => states.account.appOptions);
  const allTypeOptions = useStoreState((states) => states.account.typeOptions);
  const [app, setApp] = useState<string | undefined>();
  const [typeIds, setTypeIds] = useState<number[] | undefined>();

  useEffect(() => {
    let nextApp = formValues?.app;
    let nextTypeIds = formValues?.typeIds;
    const appInfo = _.find(
      apps,
      ({ appId, platform }) => `${appId}-${platform}` === nextApp
    );
    // TODO: 频繁触发、逻辑复杂，待优化
    if (!nextApp || !appInfo) {
      nextApp = appOptions?.[0]?.value;
      nextTypeIds = undefined;
    } else if (nextTypeIds) {
      _.remove(
        nextTypeIds,
        (nextId) => !_.find(appInfo.limitIds, (id) => nextId === id)
      );
    }
    app !== nextApp && setApp(nextApp);
    typeIds !== nextTypeIds && setTypeIds(nextTypeIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  return (
    <FilterForm
      labelCol={{ span: 6 }}
      colProps={COL_PROPS}
      resetButton
      submitButton
      formValues={formValues}
      {...restFilterFormProps}
    >
      <Form.Item label='应用' name='app'>
        <Select
          showSearch
          optionFilterProp='label'
          options={appOptions}
          value={app}
          onChange={(val: string) => {
            setApp(val);
            setTypeIds(undefined);
          }}
        />
      </Form.Item>
      <Form.Item
        shouldUpdate={(preValues, curValues) => preValues.app !== curValues.app}
        noStyle
      >
        {({ getFieldsValue, setFieldsValue }) => {
          const { app, typeIds: fieldTypeIds } = getFieldsValue();
          const appInfo = _.find(
            apps,
            ({ appId, platform }) => `${appId}-${platform}` === app
          );
          let typeOptions = appInfo
            ? _.filter(
              allTypeOptions,
              ({ value }) => !!_.find(appInfo.limitIds, (id) => value === id)
            )
            : undefined;

          if (typeIds !== fieldTypeIds) {
            setFieldsValue({ typeIds });
          }
          return (
            <Col {...COL_PROPS}>
              <Form.Item label='投放平台' name='typeIds'>
                <MultiSelect
                  allButton={false}
                  placeholder='请选择投放平台'
                  options={typeOptions}
                  value={typeIds}
                  onChange={(val: number[]) => setTypeIds(val)}
                />
              </Form.Item>
            </Col>
          );
        }}
      </Form.Item>
      <Form.Item label='渠道信息' name='ids'>
        <ChannelTagSelect app={app} typeIds={typeIds} />
      </Form.Item>
      <Form.Item label='观测时间' name='rangeDate'>
        <DateRangePicker
          valueType='string'
          picker='date'
          allowClear={false}
          disabledDate={disabledDate}
        />
      </Form.Item>
    </FilterForm>
  );
}
