import { RefObject, useRef } from 'react';
import { FadeTransition, IHttpError, LaunchScreen } from '@meitu/rake';

import AppError from '@/AppError';
import App from '@/App';
import { useLaunchScreenVisible } from '@/hooks';

function renderAppEntry(
  nodeRef: RefObject<HTMLDivElement>,
  visible: boolean,
  error?: IHttpError
): [string, JSX.Element] {
  if (error) {
    return ['error', <AppError ref={nodeRef} error={error} />];
  }

  if (visible) {
    return ['launch-screen', <LaunchScreen ref={nodeRef} />];
  }

  return ['app', <App ref={nodeRef} />];
}

export default function AppEntry() {
  const nodeRef = useRef<HTMLDivElement>(null);
  const [visible, error] = useLaunchScreenVisible();
  const [transitionKey, children] = renderAppEntry(nodeRef, visible, error);

  return (
    <FadeTransition transitionKey={transitionKey} nodeRef={nodeRef}>
      {children}
    </FadeTransition>
  );
}
