import {createInstance} from '@/api/utils';
import {CustomOptionsApiResponse, ListApiResponse, PagingListApiResponse} from '@/types';
import {
    Manuscript,
    ManuscriptFormValues,
    ManuscriptGroupsItem,
    ManuscriptsGroupsType,
    ManuscriptsQueryParams
} from '@/types/talent/scripts';
import _ from "lodash";
import {CancelToken} from "axios";

const baseURL = '/api/script';
const instance = createInstance({baseURL});

export function addScriptApi(params: ManuscriptFormValues) {
    return instance
        .post<void, { data: number }>('', params)
        .then(({data}) => data);
}


export function fetchRelateScriptListApi(
    type: ManuscriptsGroupsType,
    propertyId?: number
) {
    return instance
        .get<void, ListApiResponse<ManuscriptGroupsItem>>(`/relateList`, {
            params: {
                propertyId
            }
        })
        .then(({list}) => list);
}

export function deleteScriptApi(ids: number[]) {
    return instance.delete<void, void>(`/${ids.join(',')}`);
}


/**
 * 获取渠道管理列表
 * NOTE: 与应用关联
 * @description {@link https://api-mock.meitu-int.com/project/1682/interface/api/70067}
 */
export function fetchScriptListApi(
    {page, filters}: ManuscriptsQueryParams,
    cancelToken?: CancelToken
) {
    console.log({
        ...page,
        ...filters,
    })
    return instance
        .get<void, PagingListApiResponse<Manuscript>>('', {
            params: {
                ...page,
                ...filters,
            }
        })
        .then(({list, total, pageNum, pageSize}) => {
            return {
                list: _.map(list, (record, index) => {
                    return {
                        ...record,
                        index: (pageNum - 1) * pageSize + index + 1
                    };
                }),
                total
            };
        });
}


export function importScriptApi(
    params: FormData
) {
    return instance.post<void,
        {
            list: Array<string>;
            total: number; // 成功导入数量
        }>(`/import`, params);
}

export function updateScriptApi(id: number, params: ManuscriptFormValues) {
    return instance.post<void, void>(`/${id}`, params);
}


/**
 * 获取媒体类型下拉列表
 * @description {@link }
 */
export function fetchMediaTypeApi() {
    return instance
        .get<void, ListApiResponse<CustomOptionsApiResponse>>('/mediaTypes')
        .then(({list}) => list);
}

/**
 * 获取内容类型下拉列表
 * @description {@link }
 */
export function fetchContentTypeApi() {
    return instance
        .get<void, ListApiResponse<CustomOptionsApiResponse>>('/contentTypes')
        .then(({list}) => list);
}


export const EXPORT_SCRIPT_URL = `${baseURL}/export`;