import { forwardRef, useState } from 'react';
import { Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { BasicAppError, IHttpError } from '@meitu/rake';
import { onLogout } from '@/utils';

interface AppErrorProps {
  error: IHttpError;
}

export default forwardRef<HTMLDivElement, AppErrorProps>(function AppError(
  { error },
  ref
) {
  const [loading, setLoading] = useState(false);

  // NOTE: 退出登录后自动跳转到登录页面
  const logout = async () => {
    setLoading(true);
    await onLogout();
    setLoading(false);
  };

  return (
    <BasicAppError
      error={error}
      extra={
        <Button
          type="primary"
          icon={<LogoutOutlined />}
          loading={loading}
          onClick={logout}
        >
          重新登录
        </Button>
      }
    />
  );
});
