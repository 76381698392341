import { FetchableSelect, FetchableSelectProps } from '@meitu/rake';

import { useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import { getChannelList } from '@/api';

import axios, { CancelTokenSource } from 'axios';
const CancelToken = axios.CancelToken;
let optionSource: CancelTokenSource;
const cancelMsg = '取消上一次 getChannelList 请求';

type ValueType = number[] | undefined;
type Option = { value: number; label: string };

interface ChannelTagSelectProps
  extends Omit<FetchableSelectProps<ValueType>, 'options' | 'onFetch' | 'ref'> {
  app: string | undefined;
  typeIds?: number[];
}

/**
 * 渠道信息选择器。
 * app 切换时更新数据
 */
export default function ChannelTagSelect({
  app,
  typeIds,
  ...restProps
}: ChannelTagSelectProps) {
  const [options, setOptions] =
    useState<{ value: number; label: string; typeId: number }[]>();

  const fetchList = async (app?: string) => {
    if (!app) return Promise.reject();
    const [appId, platform] = app?.split('-') ?? [];
    try {
      optionSource?.cancel(cancelMsg);
      optionSource = CancelToken.source();
      const list = await getChannelList(
        { appId: +appId, platform: +platform },
        optionSource.token
      );
      setOptions(
        list.map(({ id, tag, typeId }) => ({
          value: id,
          label: tag,
          typeId
        }))
      );
    } catch (err) {
      // @ts-ignore
      if (err?.message === cancelMsg) {
        return;
      }
      setOptions(undefined);
    }
  };

  useEffect(() => {
    fetchList(app);
  }, [app]);

  const filterOptions = useMemo(
    () =>
      _.isEmpty(typeIds)
        ? options?.map(({ value, label }) => ({
            value,
            label
          }))
        : _.reduce(
            options,
            (acc, { value, label, typeId }) => {
              if (!!_.find(typeIds, (id) => typeId === id)) {
                acc.push({ value, label });
              }
              return acc;
            },
            [] as Option[]
          ),
    [typeIds, options]
  );

  return (
    <FetchableSelect
      mode="multiple"
      allButton={false}
      prefetch={false}
      placeholder="请选择渠道标识"
      options={filterOptions}
      {...restProps}
    />
  );
}
