import { ExportButton } from '@/components';
import { EXPORT_ACTIVATE_OVERVIEW_URL } from '@/api';

import { ActivateFilterValues } from '@/types';
import { useStoreState } from '@/store';
import {
  transformRangeToStartEndObj,
  transformGetArrayParams
} from '@meitu/rake';

import qs from 'qs';

const getExportUrl = (filters: ActivateFilterValues | undefined) => {
  if (!filters) return '';
  const [appId, platform] = filters.app?.split('-') ?? [];
  const newFilters = {
    ...transformRangeToStartEndObj(filters.rangeDate),
    appId,
    platform,
    typeIds: filters.typeIds,
    ids: filters.ids
  };
  return `${EXPORT_ACTIVATE_OVERVIEW_URL}?${qs.stringify(
    transformGetArrayParams(newFilters)
  )}`;
};

export function ExportAction() {
  const list = useStoreState((state) => state.activate.overviewList);
  const filters = useStoreState((state) => state.activate.overviewFilters);

  return list && list.length > 0 ? (
    <ExportButton getUrl={() => getExportUrl(filters)} title="数据导出" />
  ) : null;
}
