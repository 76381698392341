import ScriptKocColumnEditorAction from '@/components/talent/ScriptKocColumnEditorAction';
import { useStoreState, useStoreActions } from '@/store';

export default function ManuscriptCustomColumn() {
  const custormColumnKeys = useStoreState(
    (state) => state.talent.scriptKoc.customColumnKeys
  );
  const setCustormColumnKeys = useStoreActions(
    (state) => state.talent.scriptKoc.setCustomColumnKeys
  );
  return (
    <ScriptKocColumnEditorAction
      indicatorKeys={custormColumnKeys}
      onConfirm={(keys) => setCustormColumnKeys(keys)}
    />
  );
}
