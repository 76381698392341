import {ApartmentOutlined} from '@ant-design/icons';
import {RouteItem} from '@meitu/rake';
import Channel, {Activate} from "@/containers/Channel";

const channelRoute: RouteItem = {
    path: '/channel',
    title: '激活分析',
    icon: <ApartmentOutlined/>,
    component: Channel,

    children: [
        {
            path: 'activate',
            title: '激活概况',
            component: Activate
        }
    ]
};


/* 限制在指定项目下加载路由 */
export function useChannelRoute() {
    /* 限制在指定项目下加载路由 */
    return {
        ...channelRoute,
        children: channelRoute.children
    }
}
export default channelRoute;
