import {FetchableSelectProps, FetchableSelectRef, useWatch} from '@meitu/rake';

import {useMemo, useRef} from 'react';
import {useStoreActions, useStoreState} from '@/store';
import {Select} from 'antd';
import {ManuscriptsGroupsType} from "@/types/talent/scripts";

interface ManuscriptLabelsSelectProps<ValueType>
  extends Omit<
    FetchableSelectProps<ValueType>,
    'onFetch' | 'options' | 'onChange'
  > {
  /** 关联类型 */
  groupsType: ManuscriptsGroupsType;
  /** 切换应用后重置选中数据值 */
  resetValue?: ValueType;
}

/**
 * 渠道标签下拉选择器
 */
export default function ManuscriptLabelsSelect<
  ValueType = string | string[] | number | number[]
>({
  groupsType,
  resetValue,
  ...restProps
}: ManuscriptLabelsSelectProps<ValueType>) {
  const fetchableSelectRef = useRef<FetchableSelectRef<ValueType>>(null);
  const fetchGroupsOptions = useStoreActions(
      (actions) => actions.talent.scriptKoc.fetchGroupsOptions
  );
  const groupsOptionsMap = useStoreState(
    (state) => state.talent.scriptKoc.groupsOptionsMap
  );
  const list = useMemo(
    () => groupsOptionsMap?.[groupsType] ?? [],
    [groupsOptionsMap, groupsType]
  );
  const options = useMemo<FetchableSelectProps<ValueType>['options']>(
    () =>
      list.map(({ id, name }) => ({
        value: id,
        label: name
      })),
    [list]
  );

  /** 应用变化、类型变化时刷新下拉列表 */
  useWatch([groupsType], (prev) => {
    fetchableSelectRef?.current?.resetOptions(resetValue);
  });

  return (
    <Select
      mode="tags"
      tokenSeparators={[',']}
      style={{ width: '100%' }}
      placeholder="请选择标签，支持输入名称新建标签，多个以英文逗号分隔"
      options={options}
      optionFilterProp="label"
      {...restProps}
    />
  );
}
