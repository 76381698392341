import { Button, Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { ReactNode } from 'react';

import classNames from 'classnames';
import typographyStyle from '@/styles/typography.module.less';
import styles from './index.module.less';

export interface TemplateDownloadProps {
  downloadTemplate: Function;
  templateTips?: ReactNode | string;
}

/* 模板下载按钮、提示 */
export function TemplateDownload({
  downloadTemplate,
  templateTips
}: TemplateDownloadProps) {
  return (
    <>
      <Button
        type="link"
        className={classNames(styles.downloadButton, typographyStyle.linkLabel)}
        onClick={() => downloadTemplate()}
      >
        模板下载
      </Button>
      {templateTips && (
        <Popover placement="rightTop" arrowPointAtCenter content={templateTips}>
          <Button type="link" className={typographyStyle.linkLabel}>
            <InfoCircleOutlined />
          </Button>
        </Popover>
      )}
    </>
  );
}
