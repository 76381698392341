import { createTypedHooks, createStore } from 'easy-peasy';
import { StoreModel } from '@/types';

import account from './account';
import activate from './activate';
import talent from './talent';


const store = createStore<StoreModel>({
  account,
  activate,
  talent
});

const typedHooks = createTypedHooks<StoreModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

Object.assign(window, { __store__: store });

export default store;
