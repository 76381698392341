import {
  ManuscriptsDimensionType
} from '@/types';
export * from './manuscripts';

// TODO: 优化ts
export type AllIndicatorType =
  | ManuscriptsDimensionType


export interface IndicatorOptionItem {
  key?: AllIndicatorType | string;
  title?: string;
  tips?: React.ReactNode;
  simpleTips?: React.ReactNode;
  isRate?: boolean;
  precision?: number;
  /** 自定义表格列宽 */
  columnWidth?: string | number;
  /** 分组 */
  category?: string;
  /** 是否禁用 */
  disabled?: boolean;
}
export function getIndicatorModalOptions<IndicatorKey extends AllIndicatorType>(
  keyArr: IndicatorKey[],
  optionsMap: Record<IndicatorKey, IndicatorOptionItem>
): (IndicatorOptionItem & {
  key: IndicatorKey;
})[] {
  return keyArr.map((key) => ({
    ...optionsMap[key],
    key
  }));
}

export function getIndicatorSelectOptions<
  IndicatorKey extends AllIndicatorType
>(
  keyArr: IndicatorKey[],
  optionsMap: Record<IndicatorKey, IndicatorOptionItem>
) {
  return keyArr.map((key) => ({
    ...optionsMap[key],
    label: optionsMap[key]?.title ?? '',
    value: key
  }));
}
