import {useContext} from 'react';
import {Button, Space} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {EditorType} from '@/types';
import {GroupsContext} from './context';

export default function GroupsFormAction() {
    const {groupsEditorRef, groupsLabel} = useContext(GroupsContext);

    return (
        <Space size="middle">
            <Button
                type="primary"
                icon={<PlusOutlined/>}
                onClick={() => groupsEditorRef?.current?.open(EditorType.Add)}
            >
                添加{groupsLabel}
            </Button>
        </Space>
    );
}
