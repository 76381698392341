import {
  ReactNode,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useMemo
} from 'react';
import { Link, useHistory, useLocation, matchPath } from 'react-router-dom';
import { Tabs, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import styles from './index.module.less';
import _ from 'lodash';

export interface TabsPaneItem {
  path: string;
  title: string | ReactNode;
  tips?: string | ReactNode;
  icon?: ReactNode;
}

export interface PageTabsProps {
  tabsPanes: TabsPaneItem[];
}

export interface PageTabsRef {
  activeKey: string | undefined;
  setActiveKey(path: string): void;
}

function useActiveKey(tabsPanes: TabsPaneItem[]) {
  const { pathname } = useLocation();
  return useMemo(() => {
    for (const { path } of tabsPanes) {
      if (matchPath(pathname, { path })) {
        return path;
      }
    }
    return undefined;
  }, [pathname, tabsPanes]);
}

export const PageTabs = forwardRef<PageTabsRef, PageTabsProps>(
  function PageTabs({ tabsPanes }: PageTabsProps, ref) {
    const history = useHistory();
    const activeKey = useActiveKey(tabsPanes);
    const setActiveKey = (path: string) => {
      if (path !== activeKey) {
        history.replace(path);
      }
    };

    // 默认选中第一项
    useEffect(() => {
      if (!activeKey && !_.isEmpty(tabsPanes)) {
        setActiveKey(tabsPanes[0].path);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeKey]);

    useImperativeHandle(ref, () => ({
      activeKey,
      setActiveKey
    }));

    return (
      <Tabs size="large" activeKey={activeKey} className={styles.tabs}>
        {tabsPanes.map(({ path, icon, title, tips }) => (
          <Tabs.TabPane
            key={path}
            tab={
              <>
                {activeKey === path ? 
                  title : <Link to={path}>{title}</Link>
                }
                {tips ? (
                  <Tooltip
                    overlayClassName={styles.tipOverlay}
                    className={styles.tipIcon}
                    placement="topLeft"
                    arrowPointAtCenter
                    title={tips}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                ) : null}
              </>
              }
            />
        ))}
      </Tabs>
    );
  }
);
