import { forwardRef, useImperativeHandle, useState } from 'react';
import { Form, message, Modal, Typography } from 'antd';
import { TrimInput } from '@meitu/rake';
import { resetPassword } from '@/api';
import { redirectToLogin } from '@/api/utils';
import { PASSWORD_REGEX, PASSWORD_REGEX_MSG } from '@/constants';

export interface ResetPwdModalRef {
  open(): void;
}

export interface ResetPwdModalProps {
  onSuccess?: () => void;
}

export interface ResetPwdFormValues {
  /** 原密码  */
  oriPwd: string;
  /** 新密码  */
  newPwd: string;
  /** 确认新密码  */
  rePwd: string;
}

export default forwardRef<ResetPwdModalRef, ResetPwdModalProps>(
  function UserEditor(props, ref) {
    const [form] = Form.useForm<ResetPwdFormValues>();
    const [visible, setVisible] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const onAfterClose = () => {
      form.resetFields();
    };

    const onSubmit = async (formValues: ResetPwdFormValues) => {
      setSubmitting(true);
      let isSuccess = false;
      try {
        await resetPassword(formValues.oriPwd, formValues.newPwd);
        message.success('站外用户密码重置成功');
        setTimeout(() => {
          redirectToLogin();
        }, 1000);
        isSuccess = true;
      } catch {}
      if (isSuccess) {
        props.onSuccess?.();
        setVisible(false);
      }
      setSubmitting(false);
    };

    useImperativeHandle(ref, () => ({
      open: () => {
        setVisible(true);
        form.setFieldsValue({});
      }
    }));

    return (
      <Modal
        width={600}
        visible={visible}
        title="密码重置"
        maskClosable={false}
        okButtonProps={{ loading: submitting }}
        destroyOnClose
        onOk={() => form.validateFields().then(onSubmit)}
        onCancel={() => setVisible(false)}
        afterClose={onAfterClose}
      >
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
          <p style={{ textAlign: 'center' }}>
            <Typography.Text type="warning">
              tips：可自行重置密码，若忘记密码可联系对接人进行重置操作
            </Typography.Text>
          </p>
          <Form.Item
            label="原密码"
            name="oriPwd"
            required
            validateTrigger="onBlur"
            rules={[
              {
                validator(rule, value) {
                  if (!value) {
                    rule.message = '请输入原密码';
                    return Promise.reject();
                  }
                  if (value.length < 8 || value.length > 16) {
                    rule.message = '输入8-16位密码';
                    return Promise.reject();
                  }
                  if (!value.match(PASSWORD_REGEX)) {
                    rule.message = PASSWORD_REGEX_MSG;
                    return Promise.reject();
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <TrimInput
              inputType="password"
              placeholder="至少8位字符，且必须有大小写字母和数字"
            />
          </Form.Item>
          <Form.Item
            label="新密码"
            name="newPwd"
            required
            validateTrigger="onBlur"
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value) {
                    rule.message = '请输入新密码';
                    return Promise.reject();
                  }
                  if (value.length < 8 || value.length > 16) {
                    rule.message = '输入8-16位密码';
                    return Promise.reject();
                  }
                  if (value === getFieldValue('oriPwd')) {
                    rule.message = '新密码不应与原密码相同';
                    return Promise.reject();
                  }
                  if (!value.match(/^[a-z0-9$@!%*#_~?&]+?$/i)) {
                    rule.message =
                      '请输入正确的密码格式。仅支持大小写字母、数字和特殊符号（$@!%*#_~?&）';
                    return Promise.reject();
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            <TrimInput
              inputType="password"
              placeholder="至少8位字符，且必须有大小写字母和数字"
            />
          </Form.Item>
          <Form.Item
            label="再次输入新密码"
            name="rePwd"
            required
            validateTrigger="onBlur"
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value) {
                    rule.message = '请再次输入新密码';
                    return Promise.reject();
                  }
                  if (value !== getFieldValue('newPwd')) {
                    rule.message = '两次密码不一致';
                    return Promise.reject();
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            <TrimInput
              inputType="password"
              placeholder="至少8位字符，且必须有大小写字母和数字"
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);
