/**
 * 表单：弹窗(600px)中的表单默认布局
 */
export const DEFAULT_MODAL_FORM_COL = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
};

/**
 * 表单：表格中的表单默认布局
 */
export const DEFAULT_TABLE_FORM_COL = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 }
};

/**
 * 筛选表单的“全部”选项
 */
export const ALL_OPTION_VALUE = -1;
export const ALL_OPTION_VALUE_STRING = ALL_OPTION_VALUE.toString();

/**
 * 全部应用-APP-ID
 */
export const ALL_APP_ID = 999;

/**
 * 设计室网页整体-APP-ID
 */
export const DESIGN_APP_ID = 998;
