import { forwardRef } from 'react';
import { Button, ButtonProps } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { downloadFile } from './utils';

interface ExportButtonProps extends ButtonProps {
  url?: string;
  getUrl?: () => string;
}

export default forwardRef<HTMLDivElement, ExportButtonProps>(
  function ExportButton({ url, getUrl, title = '导出', ...restProps }, ref) {
    return (
      <Button
        type="default"
        icon={<DownloadOutlined />}
        onClick={() => {
          const exportUrl = getUrl ? getUrl() : url;
          console.info('[export] url:', exportUrl);
          if (exportUrl) {
            downloadFile(exportUrl);
          }
        }}
        {...restProps}
      >
        {title}
      </Button>
    );
  }
);
