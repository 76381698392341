/**
 * 文字换行
 * NOTE: 需要自行指定宽度。
 */

export function renderBreakWord(val: string) {
  return val ? (
    <span style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
      {val}
    </span>
  ) : (
    <>-</>
  );
}

export default function BreakWord({ word }: { word: string }) {
  return renderBreakWord(word);
}
