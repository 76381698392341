import {
    DEFAULT_PAGE,
    ListQueryParams,
    ListQueryParamsTransformer, transformToStringArray, transformToNumberArray,
    useFilterableListQueryParams,
    useIsMountedRef
} from '@meitu/rake';

import {useState} from 'react';
import {useStoreActions, useStoreState} from '@/store';
import {ScriptsFilterValues} from "@/types/talent/scripts";
import {SCRIPT_KOC_FILTER_DEFAULT_VALUES} from "@/constants/talent/manuscripts";


/**
 * 数据转换器。Record<string, string> --> Record<string, any>
 */
const transformToFilterValues: ListQueryParamsTransformer<ScriptsFilterValues> =
    ({appIds, labelIds, contentTypeIds, mediaTypeIds, ...extraQueryParams}) => {
        const trans = {
            appIds: transformToNumberArray(appIds),
            labelIds: transformToNumberArray(labelIds),
            contentTypeIds: transformToStringArray(contentTypeIds),
            mediaTypeIds: transformToStringArray(mediaTypeIds),
            ...extraQueryParams
        } as ScriptsFilterValues;
        // console.log('transformToFilterValues', trans);
        return trans;
    };

/**
 * 渠道管理：筛选表单默认值
 */
export const initialFilterValues: ScriptsFilterValues = Object.freeze({
    ...SCRIPT_KOC_FILTER_DEFAULT_VALUES,
    link: '',
    expertInfo: '',
});

/**
 * 渠道管理：数据请求
 */
const useManuscriptFetch = () => {
    const isMounted = useIsMountedRef();
    const [loading, setLoading] = useState(false);
    const fetchManuscriptList = useStoreActions(
        (actions) => actions.talent.scriptKoc.fetchManuscriptList
    );
    const list = useStoreState((state) => state.talent.scriptKoc.manuscript);
    const total = useStoreState((state) => state.talent.scriptKoc.total);

    /** 发送请求并筛选数据 */
    const onFetchData = ({
                             filters = initialFilterValues,
                             page = DEFAULT_PAGE
                         }: ListQueryParams<ScriptsFilterValues> = {}) => {
        setLoading(true);
        fetchManuscriptList({
            page,
            filters
        }).finally(() => {
            if (!isMounted.current) return;
            setLoading(false);
        });
    };
    return {loading, list, total, onFetchData};
};

const useManuscriptQuery = () => {
    const [queryParams, setQueryParams] =
        useFilterableListQueryParams<ScriptsFilterValues>(transformToFilterValues);
    const {loading, list, total, onFetchData} = useManuscriptFetch();

    /** 重新加载（使用原本的查询数据） */
    const onReload = () => {
        onFetchData(queryParams);
    };

    /** 更新查询参数（筛选、分页、排序）*/
    const onChangeQueryParams = (
        newQueryParams: ListQueryParams<ScriptsFilterValues>
    ) => {
        setQueryParams(newQueryParams);
        onFetchData(newQueryParams);
    };


    return {
        loading,
        list,
        total,
        queryParams,
        onChangeQueryParams,
        onReload
    };
};

export default useManuscriptQuery;
