import {Button, Space} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import ManuscriptBatchUpload from './actions/ManuscriptBatchUpload';

import {useContext} from 'react';
import {useStoreActions} from '@/store';
import {ManuscriptContext} from './context';
import {EditorType} from '@/types';
import {ManuscriptsGroupsType} from "@/types/talent/scripts";

interface ManuscriptFormActionProps {
    onReload?(): void;
}

export default function ManuscriptFormAction({
                                                 onReload
                                             }: ManuscriptFormActionProps) {
    const {manuscriptEditorRef} = useContext(ManuscriptContext);
    const fetchGroupsOptions = useStoreActions(
        (actions) => actions.talent.scriptKoc.fetchGroupsOptions
    );

    return (
        <Space size="middle">
            <Button
                type="primary"
                icon={<PlusOutlined/>}
                onClick={() => manuscriptEditorRef?.current?.open(EditorType.Add)}
            >
                添加稿件
            </Button>
            <ManuscriptBatchUpload
                onReload={() => {
                    // NOTE: 批量添加可能新增代理商、acid，需要更新数据。暂不支持批量添加标签数据。
                    fetchGroupsOptions({groupsType: ManuscriptsGroupsType.Labels});
                    onReload?.();
                }}
            />
        </Space>
    );
}
