import {forwardRef, useEffect} from 'react';
import {FilterArticle} from '@meitu/rake';
import ActivateForm from './ActivateForm';
import ActivateList from './ActivateList';
import useActivateQuery from './useActivateQuery';

export default forwardRef<HTMLDivElement>(function Activate(props, ref) {
    /** 查询列表 */
    const {
        initialFilterValues,
        loading,
        list,
        queryParams,
        onChangeQueryParams
    } = useActivateQuery();

    return (
        <FilterArticle
            title="激活概况"
            loading={loading}
            onChangeQueryParams={onChangeQueryParams}
            queryParams={queryParams}
            initialFilterValues={initialFilterValues}
            form={<ActivateForm/>}
            table={<ActivateList dataSource={list}/>}
        />
    );
});
