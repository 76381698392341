import {
  FilterArticleActionType,
  ListQueryParams,
  ListQueryParamsTransformer,
  isInitalAction,
  isPageAction,
  useFilterableListQueryParams,
  transformToNumberArray,
  transformRangeStringToArray,
  useIsMountedRef
} from '@meitu/rake';

import { useState, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { message } from 'antd';
import { useStoreState, useStoreActions } from '@/store';

import { ActivateFilterValues } from '@/types';

/**
 * 数据转换器。Record<string, string> --> Record<string, any>
 */
const transformToFilterValues: ListQueryParamsTransformer<ActivateFilterValues> =
  ({ typeIds, ids, rangeDate, ...extraQueryParams }) => {
    const trans = {
      typeIds: transformToNumberArray(typeIds),
      ids: transformToNumberArray(ids),
      rangeDate: transformRangeStringToArray(rangeDate),
      ...extraQueryParams
    } as ActivateFilterValues;
    // console.log('transformToFilterValues', trans);
    return trans;
  };

/**
 * 数据请求
 */
const useActivateFetch = () => {
  const isMounted = useIsMountedRef();
  const [loading, setLoading] = useState(false);
  const fetchOverviewList = useStoreActions(
    (actions) => actions.activate.fetchOverviewList
  );
  const list = useStoreState((state) => state.activate.overviewList);

  /**
   * 筛选表单默认值
   */
  const appOptions = useStoreState((state) => state.account.appOptions);
  const initialFilterValues: ActivateFilterValues = useMemo(() => {
    return Object.freeze({
      app: _.first(appOptions)?.value,
      typeIds: undefined,
      ids: undefined,
      rangeDate: [
        // 最近1天（包括当天）
        moment().subtract(0, 'day').format('YYYY-MM-DD'),
        moment().subtract(0, 'day').format('YYYY-MM-DD')
      ]
    });
  }, [appOptions]);

  /** 发送请求并筛选数据 */
  const onFetchData = (filters = initialFilterValues) => {
    if (!filters.app) {
      message.error('请选择应用！');
      return;
    }
    setLoading(true);
    // console.log('onFetchData', filters);
    fetchOverviewList(filters).finally(() => {
      if (!isMounted.current) return;
      setLoading(false);
    });
  };

  return { initialFilterValues, loading, list, onFetchData };
};

const useActivateQuery = () => {
  const [queryParams, setQueryParams] =
    useFilterableListQueryParams<ActivateFilterValues>(transformToFilterValues);
  const { initialFilterValues, loading, list, onFetchData } =
    useActivateFetch();
  const appOptions = useStoreState((states) => states.account.appOptions);
  const typeOptions = useStoreState((states) => states.account.typeOptions);

  /** 更新查询参数（筛选、分页、排序）*/
  const onChangeQueryParams = async (
    newQueryParams: ListQueryParams<ActivateFilterValues>,
    actionKey: FilterArticleActionType
  ) => {
    if (isInitalAction(actionKey)) {
      // 去除不合法的数据
      // TODO: 添加到 rake
      let nextApp = newQueryParams?.filters?.app;
      if (!nextApp || !_.find(appOptions, ({ value }) => value === nextApp)) {
        _.set(newQueryParams, 'filters.app', appOptions?.[0]?.value);
      }
      let nextTypeIds = newQueryParams?.filters?.typeIds;
      nextTypeIds &&
        _.remove(
          nextTypeIds,
          (id) => !_.find(typeOptions, ({ value }) => value === id)
        );
      _.set(newQueryParams, 'filters.typeIds', nextTypeIds);
    }
    setQueryParams(newQueryParams);
    if (isPageAction(actionKey)) {
      // 客户端分页：分页、排序时不发送请求
      return;
    }
    onFetchData(newQueryParams?.filters);
  };

  return {
    initialFilterValues,
    loading,
    list,
    queryParams,
    onChangeQueryParams
  };
};

export default useActivateQuery;
