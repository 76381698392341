import { PlatformType } from '@/types';

export const PLATFORM_MAP = new Map<number, string>([
  [PlatformType.Android, 'Android'],
  [PlatformType.iOS, 'iOS'],
  [PlatformType.iPad, 'iPad'],
  [PlatformType.web, 'web'],
  [PlatformType.Windows, 'Windows'],
  [PlatformType.Mac, 'Mac'],
  [PlatformType.Linux, 'Linux'],
  [PlatformType.MINI_DY, '抖音'],
  [PlatformType.MINI_WX, '微信']
]);
