import {useEffect, useState} from 'react';
import {HttpError} from '@meitu/rake';
import {matchPath, useLocation, useHistory} from 'react-router-dom';
import {useStoreActions} from '@/store';
import routes from '@/routes';

export * from './account';

export function useLaunchScreenVisible() {
    const [error, setError] = useState<HttpError>();
    const [visible, setVisible] = useState<boolean>(true);
    const history = useHistory();

    const fetchAccountProfile = useStoreActions(
        (actions) => actions.account.fetchAccountProfile
    );

    useEffect(
        () => {
            const fetchAccount = (redirect?: string) => {
                setVisible(true);
                fetchAccountProfile()
                    .then(() => {
                        setVisible(false);
                        if (redirect) {
                            history.replace(redirect);
                        }
                    })
                    .catch((err) => {
                        setVisible(false);
                        setError(err);
                        // redirectToLogin();
                    });
            };

            fetchAccount();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return [visible, error] as const;
}

export function useAppTransitionKey() {
    const location = useLocation();

    for (const {path} of routes) {
        if (matchPath(location.pathname, {path})) {
            return 'app';
        }
    }

    return location.pathname;
}
