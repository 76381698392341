import { createInstance } from '@/api/utils';

import { CancelToken } from 'axios';
import { ListApiResponse, ChannelItem, AppId } from '@/types';

const instance = createInstance({ baseURL: '/api' });

/**
 * 获取渠道列表
 * @description {@link https://api-mock.meitu-int.com/project/1793/interface/api/89009}
 */
export function getChannelList(filters: AppId, cancelToken?: CancelToken) {
  return instance
    .get<void, ListApiResponse<ChannelItem>>('/channels', {
      params: {
        appId: filters.appId,
        platform: filters.platform,
        pageNum: 1,
        pageSize: 5000
      },
      cancelToken
    })
    .then(({ list }) => list);
}
