import { useStoreState } from '@/store';
import { useMemo } from 'react';
import _ from 'lodash';

import { SiderAuthMenuItem } from '@/types';

function getFirstReachableRoute(
  routes: SiderAuthMenuItem[],
  parentRoute?: string
): string {
  const firstRoute = _.first(routes);

  return firstRoute?.children
    ? getFirstReachableRoute(firstRoute.children)
    : firstRoute?.route ?? parentRoute ?? '';
}

function getRoute(
  routes: SiderAuthMenuItem[],
  name: string
): string | undefined {
  for (const route of routes) {
    if (route.route === name) {
      return route.route;
    }

    if (route.children) {
      const matchRoute = getRoute(route.children, name);

      if (matchRoute) {
        return matchRoute;
      }
    }
  }

  return undefined;
}

/**
 * 第一个有能访问的页面路由
 */
export function useFirstReachableRoute() {
  const authMenuTree = useStoreState((state) => state.account.authMenuTree);

  return useMemo(() => getFirstReachableRoute(authMenuTree), [authMenuTree]);
}

/**
 * 指定路由名称返回路径
 */
export function useGetRouteByName() {
  const authMenuTree = useStoreState((state) => state.account.authMenuTree);
  const firstReachableRoute = useFirstReachableRoute();

  return (name: string) => getRoute(authMenuTree, name) ?? firstReachableRoute;
}
