import { getRouteMatchers, RouteItem } from '@meitu/rake';
import globalRoutes from './global';
import channelRoutes from './channel';
import talentRoute from "./talent";


export { globalRoutes, channelRoutes , talentRoute};
export * from './talent';
export * from './channel';

const routes: RouteItem[] = [...globalRoutes, channelRoutes, talentRoute];

/**
 * 路由匹配器列表
 * @description 用来进行路由匹配
 */
export const routeMatchers = getRouteMatchers(routes);

export default routes;
