import {createInstance} from '@/api/utils';
import {
    LoginFormValues,
    AccountInfo,
    LoginFormEmailCodeValues,
    DataApiResponse,
    ListApiResponse,
    OptionsApiResponse
} from '@/types';

const baseURL = '/api';
const instance = createInstance({baseURL});



/**
 * 达人投放应用列表
 * @description {@link }
 */
export function fetchTalentAppListApi() {
    return instance
        .get<void, ListApiResponse<OptionsApiResponse>>('/apps/talentApps')
        .then(({ list }) => list);
}

/**
 * 登录(验证账号和密码)
 * @description {@link https://api-mock.meitu-int.com/project/1793/interface/api/88388}
 */
export function login(formValues: LoginFormValues) {
    return instance.post<void, DataApiResponse<number>>('/sso/login', formValues);
}

/**
 * 登录(真正的登录，验证完账号和密码后验证邮箱)
 * @description {@link }
 */
export function loginWithEmailCode(formValues: LoginFormEmailCodeValues) {
    return instance.post<void, void>('/sso/login_email_code', formValues);
}

/**
 * 退出登录
 * @description {@link }
 */
export function verifyEmailCode() {
    return instance.get<void, DataApiResponse<number>>('/sso/verify_email_code');
}

/**
 * 退出登录
 * @description {@link https://api-mock.meitu-int.com/project/1793/interface/api/88415}
 */
export function logout() {
    return instance.get<void, void>('/sso/logout');
}

/**
 * 退出登录
 * @description {@link https://api-mock.meitu-int.com/project/1793/interface/api/89072}
 */
export function resetPassword(oriPwd: string, newPwd: string) {
    return instance.post<void, void>('/users/resetPassword', {
        oriPwd,
        newPwd
    });
}

/**
 * 获取图形验证码
 * 图片大小：110*40
 * @description {@link https://api-mock.meitu-int.com/project/1793/interface/api/88811}
 */
export const LOGIN_VERIFY_CODE_URL = `${baseURL}/sso/verify_code`;

/**
 * 获取账户及权限信息
 * @description {@link https://api-mock.meitu-int.com/project/1793/interface/api/88955}
 */
export function getAccountProfile() {
    return instance
        .get<void,
            {
                data: AccountInfo;
            }>('/users/info')
        .then(({data}) => data);
}
