import Particles, { ISourceOptions } from 'react-tsparticles';

import { forwardRef } from 'react';

import styles from './LoginBg.module.less';

export const particlesOptions: ISourceOptions = {
  interactivity: {
    detectsOn: 'canvas',
    modes: {
      bubble: {
        distance: 500,
        duration: 2,
        opacity: 0.8,
        size: 40
      },
      push: {
        quantity: 4
      },
      repulse: {
        distance: 500,
        duration: 0.4
      }
    }
  },
  particles: {
    color: {
      value: ['#ddd', '#9f9ddd', '#8786b5', '#869fb5']
    },
    links: {
      color: '#ddd',
      distance: 600,
      enable: true,
      opacity: 0.3,
      width: 5
    },
    collisions: {
      enable: true
    },
    move: {
      direction: 'none',
      enable: true,
      outMode: 'bounce',
      random: false,
      speed: 3,
      straight: false
    },
    number: {
      density: {
        enable: true,
        value_area: 1000
      },
      value: 20
    },
    opacity: {
      value: 0.5
    },
    shape: {
      type: 'circle'
    },
    size: {
      random: true,
      value: 10
    }
  },
  detectRetina: true
};

export interface LaunchScreenProps {
  prefixCls?: string;
}

export default forwardRef<HTMLDivElement, LaunchScreenProps>(function LoginBg(
  props,
  ref
) {
  return (
    <div ref={ref} className={styles.loginBg}>
      <Particles
        className={styles.loginBgParticles}
        options={particlesOptions}
      />
    </div>
  );
});
