/**
 * 平台类型
 */
export enum PlatformType {
  iOS = 1,

  Android = 2,

  iPad = 4,

  web = 7,

  Windows = 6,

  Mac = 8,

  Linux = 9,

  MINI_DY = 10,

  MINI_WX = 11,

  UNKNOWN = 99
}

/**
 * 应用
 */
export interface AppItem {
  /** 数据ID，唯一。同 appInfoId */
  id: number;
  /** 应用ID。不唯一，不同平台的相同应用 appId 相同。 */
  appId: number;
  /** 应用名称 */
  appName: string;
  /** 平台 */
  platform: PlatformType;
  /** 投放平台id */
  limitIds: number[];
}

/**
 * 应用标识
 */
export interface AppId extends Pick<AppItem, 'appId' | 'platform'> {}
