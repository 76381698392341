import {Divider, Form, message, Modal} from 'antd';
import {catchFormError, TrimInput} from '@meitu/rake';
import {RelateManuscriptTransfer} from '@/components';

import {forwardRef, useContext, useImperativeHandle, useState} from 'react';
import {GroupsContext} from './context';

import {EditorType,} from '@/types';
import {ManuscriptGroupsFormValues, ManuscriptsGroupsListItem} from '@/types/talent/scripts';
import {addScriptPropertyApi, updateScriptPropertyApi} from "@/api/talent/property";


export interface GroupsEditorRef {
    open(type: EditorType.Add): void;

    open(type: EditorType.Edit, data: ManuscriptsGroupsListItem): void;
}

export interface GroupsEditorProps {
    onSuccess?: () => void;
}

export default forwardRef<GroupsEditorRef, GroupsEditorProps>(
    function GroupsEditor({onSuccess}, ref) {
        const {groupsType, groupsLabel} = useContext(GroupsContext);

        const [form] = Form.useForm<ManuscriptGroupsFormValues>();
        const [type, setType] = useState<EditorType>(EditorType.Add);
        const [visible, setVisible] = useState(false);
        const [submitting, setSubmitting] = useState(false);
        const [currentId, setCurrentId] = useState<number>();

        const afterFetch = (checkedValues: number[]) => {
            form.setFieldsValue({scriptIds: checkedValues});
        };

        const onSubmit = async (formValues: ManuscriptGroupsFormValues) => {
            setSubmitting(true);
            try {
                switch (type) {
                    case EditorType.Add: {
                        await addScriptPropertyApi(groupsType, formValues);
                        message.success(`${groupsLabel}添加成功`);
                        break;
                    }
                    case EditorType.Edit: {
                        await updateScriptPropertyApi(
                            groupsType,
                            currentId as number,
                            formValues
                        );
                        message.success(`${groupsLabel}修改成功`);
                        break;
                    }
                }
                onSuccess?.();
                setVisible(false);
            } finally {
                setSubmitting(false);
            }
        };

        useImperativeHandle(ref, () => ({
            open: async (type: EditorType, data?: ManuscriptsGroupsListItem) => {
                setType(type);
                setVisible(true);
                setCurrentId(data?.id);
                if (data) {
                    form.setFieldsValue(data);
                }
            }
        }));

        const isAddType = type === EditorType.Add;
        return (
            <Modal
                width={700}
                visible={visible}
                title={`${isAddType ? '添加' : '编辑'}${groupsLabel}`}
                maskClosable={false}
                okButtonProps={{loading: submitting}}
                destroyOnClose
                onOk={() => form.validateFields().then(onSubmit).catch(catchFormError)}
                onCancel={() => setVisible(false)}
                afterClose={() => form.resetFields()}
            >
                <Form form={form} labelCol={{span: 5}} wrapperCol={{span: 17}}>
                    <Divider dashed/>
                    <Form.Item
                        label={`${groupsLabel}名称`}
                        name="name"
                        rules={[
                            {required: true, message: `请输入${groupsLabel}名称`},
                        ]}
                    >
                        <TrimInput placeholder={`请输入${groupsLabel}名称`}/>
                    </Form.Item>
                    <Form.Item label="稿件名称" name="scriptIds">
                        <RelateManuscriptTransfer
                            groupsType={groupsType}
                            propertyId={currentId}
                            afterFetch={afterFetch}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
);
