import {FilterArticle} from '@meitu/rake';
import GroupsForm from './GroupsForm';
import GroupsList from './GroupsList';
import GroupsEditor, {GroupsEditorRef} from './GroupsEditor';

import {forwardRef, useRef} from 'react';
import useGroupsQuery, {initialFilterValues} from './useGroupsQuery';
import {GroupsContext} from './context';
import {MANUSCRIPTS_GROUPS_MAP} from '@/constants/talent/manuscripts';

import {
    ManuscriptGroupsListFilterValues,
    ManuscriptsGroupsType
} from '@/types/talent/scripts';

interface GroupsTabPaneProps {
    groupsType: ManuscriptsGroupsType;
}

export default forwardRef<HTMLDivElement, GroupsTabPaneProps>(
    function GroupsWrapper({groupsType}, ref) {
        /** 操作弹窗 */
        const groupsEditorRef = useRef<GroupsEditorRef>(null);
        /** 查询列表 */
        const {loading, list, total, queryParams, onChangeQueryParams, onReload} =
            useGroupsQuery(groupsType);

        return (
            <GroupsContext.Provider
                value={{
                    groupsEditorRef,
                    groupsType,
                    groupsLabel: MANUSCRIPTS_GROUPS_MAP.get(groupsType)
                }}
            >
                <FilterArticle<ManuscriptGroupsListFilterValues>
                    loading={loading}
                    onChangeQueryParams={onChangeQueryParams}
                    queryParams={queryParams}
                    initialFilterValues={initialFilterValues}
                    form={<GroupsForm/>}
                    table={
                        <GroupsList dataSource={list} total={total} onReload={onReload}/>
                    }
                />
                <GroupsEditor ref={groupsEditorRef} onSuccess={onReload}/>
            </GroupsContext.Provider>
        );
    }
);
