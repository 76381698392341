import { Tag } from 'antd';

import _ from 'lodash';

import { SortOrder } from 'antd/es/table/interface';
import { TableColumn } from '@meitu/rake';
import { IndicatorOptionItem } from '@/types';
import { renderBreakWord } from '@/components';

interface ExtremumMap extends Record<string, number> {}

/**
 * 标记出每页中每列的最大、最小值
 * NOTE: 改变原数据
 * @param pageSlice 每页的数据切片
 * @return pageSlice
 */
function getExtremumOnPage<T extends Object>(
  pageSlice: T[],
  sortColumnKeys: (keyof T)[]
) {
  const maxMap: ExtremumMap = {};
  const minMap: ExtremumMap = {};
  pageSlice.forEach((row) => {
    _.forEach(sortColumnKeys, (key) => {
      const value = row[key] as unknown as number;
      if (
        _.isUndefined(maxMap[key as string]) ||
        maxMap[key as string] < value
      ) {
        maxMap[key as string] = value;
      }
      if (
        _.isUndefined(minMap[key as string]) ||
        minMap[key as string] > value
      ) {
        minMap[key as string] = value;
      }
    });
  });

  _.forEach(sortColumnKeys, (key) => {
    const maxValue = maxMap[key as string];
    const minValue = minMap[key as string];
    pageSlice.forEach((row) => {
      const value = row[key] as unknown as number;
      _.set(row, `${key as string}Max`, value === maxValue);
      _.set(row, `${key as string}Min`, value === minValue);
    });
  });
  return pageSlice;
}

/**
 * 标记出整个表格每页中每列的最大、最小值
 */
export function getExtremumTable<T extends Object>(
  list: T[] | undefined,
  pageSize: number,
  sortColumnKeys: (keyof T)[]
) {
  let formatDetail: T[] = [];
  if (!list) return formatDetail;
  const length = list.length;
  const pageCount = Math.ceil(length / pageSize);
  for (let i = 0; i < pageCount; i++) {
    const start = i * pageSize;
    const end = start + pageSize > length ? length : start + pageSize;
    const slice = list.slice(start, end);
    const sliceExtremum = getExtremumOnPage<T>(slice, sortColumnKeys);
    formatDetail = formatDetail.concat(sliceExtremum);
  }
  return formatDetail;
}

function renderExtremumFn<T extends Object>(
  key: keyof T
): TableColumn<T>['render'] {
  return (val, record) => {
    const format = record[`${key as string}Format` as keyof T] ?? val ?? '-';
    const isMax = record[`${key as string}Max` as keyof T];
    const isMin = record[`${key as string}Min` as keyof T];
    if (isMax && isMin) return format;
    if (isMax) return <Tag color="#f50">{format}</Tag>;
    if (isMin) return <Tag color="#87d068">{format}</Tag>;

    return format;
  };
}

/**
 * 获取表格列格式化配置
 * 自动支持排序、渲染最大/最小值、展示格式化后的数据
 */
export function getFormatColumns<T extends Object>(
  colList: TableColumn<T>[],
  numberColumnKeys?: (keyof T)[],
  sortColumnKeys?: (keyof T)[]
): TableColumn<T>[] {
  const newList = colList.map((colItem) => {
    const dataIndex = colItem?.dataIndex as keyof T;
    if (sortColumnKeys && sortColumnKeys.indexOf(dataIndex) >= 0) {
      return {
        ...colItem,
        sorter: true,
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: renderExtremumFn(dataIndex)
      };
    } else if (numberColumnKeys && numberColumnKeys.indexOf(dataIndex) >= 0) {
      return {
        ...colItem,
        dataIndex: `${dataIndex as string}Format`
      };
    }
    return colItem;
  });
  return newList;
}

/**
 * 获取表格列格式化配置
 * 自动支持排序、渲染最大/最小值、展示格式化后的数据
 * NOTE: has next
 */
export function getFormatColumnsNext<T extends string | symbol>(
  colList: TableColumn<{ [Key in T]?: string | number }>[],
  numberColumnKeys?: T[],
  sortColumnKeys?: T[]
): TableColumn<{ [Key in T]?: string | number }>[] {
  const newList = colList.map((colItem) => {
    const dataIndex = colItem?.dataIndex as unknown as T;
    if (sortColumnKeys && sortColumnKeys.indexOf(dataIndex) >= 0) {
      return {
        ...colItem,
        sorter: true,
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: renderExtremumFnNext(dataIndex)
      };
    } else if (numberColumnKeys && numberColumnKeys.indexOf(dataIndex) >= 0) {
      return {
        ...colItem,
        dataIndex: `${dataIndex as string}Format`
      };
    }
    return colItem;
  });
  return newList;
}

/**
 * 获取表格列格式化配置
 * 自动支持排序、渲染最大/最小值、展示格式化后的数据
 * NOTE: has next
 */
export function getCustomFormatColumns<T extends Object>(
  colList: TableColumn<T>[]
): TableColumn<T>[] {
  const newList = colList.map((colItem) => {
    // @ts-ignore
    const list = colItem.children.map((child: { dataIndex: keyof T }) => {
      const dataIndex = child?.dataIndex as keyof T;
      return { ...child, render: renderExtremumFn(dataIndex) };
    });
    // @ts-ignore
    colItem.children = list;
    const dataIndex = colItem?.dataIndex as keyof T;
    return {
      ...colItem,
      render: renderExtremumFn(dataIndex)
    };
  });
  return newList;
}

function renderExtremumFnNext<T extends string | symbol>(
  key: T
): TableColumn<{ [Key in T]?: string | number }>['render'] {
  return (val, record) => {
    const format =
      record[`${key as string}Format` as unknown as T] ?? val ?? '-';
    const isMax = record[`${key as string}Max` as unknown as T];
    const isMin = record[`${key as string}Min` as unknown as T];
    if (isMax && isMin) return format;
    if (isMax) return <Tag color="#f50">{format}</Tag>;
    if (isMin) return <Tag color="#87d068">{format}</Tag>;
    return format;
  };
}

/**
 * 添加合计表头F
 */
export function addSummaryColumn<T>(
  column: TableColumn<T>,
  title?: string | number
) {
  return {
    ...column,
    children: [
      {
        ...column,
        title: title === 0 ? '0' : title ?? '-',
        filters: false,
        sorter: false
      }
    ]
  } as TableColumn<T>;
}

/**
 * 根据选中的指标返回数据明细指标列头
 */
export function getIndicatorFormatColumns<
  ColumnItem extends Object,
  Keys extends keyof ColumnItem
>(
  indicatorKeys: Keys[],
  indicatorOptions: (IndicatorOptionItem & {
    key: Keys;
  })[],
  format: boolean = true
) {
  const pickColumns = _.reduce(
    indicatorOptions,
    (acc, { title, key, columnWidth, disabled }) => {
      if (disabled) {
        return acc;
      }
      let width = columnWidth;
      if (!width && typeof title === 'string') {
        width = title.length > 5 ? title.length * 25 : 125;
      }
      if (indicatorKeys.indexOf(key) >= 0) {
        acc.push({
          title,
          dataIndex: key as string,
          align: 'center',
          width,
          render: renderBreakWord
        });
      }
      return acc;
    },
    [] as TableColumn<ColumnItem>[]
  );

  return format
    ? getFormatColumns<ColumnItem>(pickColumns, indicatorKeys, indicatorKeys)
    : pickColumns;
}
