import axios, {AxiosRequestConfig} from 'axios';
import {toCamelCase} from '@meitu/util';
import {catchError} from '@meitu/rake';
import {message} from 'antd';
import {LOGIN_ROUTE} from '@/constants';
import qs from 'qs';
import _ from 'lodash';

/**
 * 获取重定向的地址
 */
export function getRedirectUrl() {
    const {origin, pathname, search} = window.location;
    const nextSearch = qs.stringify({redirect: `${pathname}${search}`});

    return `${origin}?${nextSearch}`;
}

export function redirectToLogin(autoLogin: boolean = true) {
    window.location.replace(LOGIN_ROUTE);
}

/** 创建 Axios 实例 */
export function createInstance(
    config?: Omit<AxiosRequestConfig, 'withCredentials'>
) {
    const instance = axios.create(
        Object.assign({}, config, {withCredentials: true})
    );
    instance.interceptors.request.use((config) => {
        // 统一处理 get 请求参数格式
        if (config.method === 'get' && config.params) {
            config.params = _.reduce(
                config.params,
                (acc, val, key) => {
                    _.set(acc, key, Array.isArray(val) ? val.join(',') : val);
                    return acc;
                },
                {}
            );
        }
        return config;
    }, _.identity);
    instance.interceptors.response.use(
        (response): any => {
            if (response.data instanceof Blob) {
                return response.data;
            }

            if (typeof response.data === 'string') {
                try {
                    response.data = JSON.parse(response.data);
                } catch (err) {
                    return response.data;
                }
            }

            if (!response.data.meta?.code) {
                return toCamelCase(response.data.response);
            }
        },
        (error) => {
            if (axios.isCancel(error)) {
                return Promise.reject(error);
            }

            const {response} = error;
            if (!response) {
                message.error('请求错误!');
                return Promise.reject(error);
            }

            const errorObj = Object.assign(error, {
                message:
                    response?.data?.meta?.msg ??
                    response?.data?.meta?.error ??
                    response?.statusText ??
                    error.message,
                errorCode: response?.data?.meta?.code ?? response.status,
                status: response?.status
            });

            console.warn('[request errorObj]', errorObj);
            switch (errorObj.errorCode) {
                case 11003:
                    // 登录失败：账号未注册
                    message.error(
                        <div
                            style={{
                                display: 'inline-block',
                                textAlign: 'left',
                                verticalAlign: 'top'
                            }}
                        >
                            <b>登录失败！</b>
                            <br/>
                            您尚未注册该系统，需联系您的对接人开通该系统的访问权限
                        </div>,
                        10
                    );
                    throw errorObj;
                default:
                    // 通用异常处理
                    switch (response.status) {
                        case 400:
                            response?.data?.meta
                                ? catchError(errorObj)
                                : message.error('请求参数错误');
                            break;
                        case 401:
                            redirectToLogin();
                            break;
                        default:
                            catchError(errorObj);
                    }
            }

            throw errorObj;
        }
    );

    return instance;
}

/**
 * DEBUG: 创建 Mock Axios 实例
 */
export function createMockInstance(pathname: string) {
    return createInstance({
        baseURL:
            process.env.NODE_ENV !== 'production'
                ? `https://api-mock.meitu-int.com/mock/1682${pathname}`
                : ''
    });
}
