import { Card, Space } from 'antd';
import { FilterTable, FilterTableProps } from '@meitu/rake';
import ManuscriptCustomColumn from './ManuscriptCustomColumn';
import ManuscriptExport from '../actions/ManuscriptExport';
import ManuscriptBatchDelete from '../actions/ManuscriptBatchDelete';

import React, { useEffect, useState } from 'react';
import useColumns from './useColumns';

import { TableRowSelection } from 'antd/es/table/interface';
import { Manuscript } from '@/types/talent/scripts';

interface ManuscriptListProps extends FilterTableProps<Manuscript> {
  onReload?(): void;
}

export default function ManuscriptList({
  dataSource,
  onReload,
  ...restFilterTableProps
}: ManuscriptListProps) {
  const columns = useColumns(onReload);

  /**
   * 多选操作
   */
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedKeys] = useState<Manuscript[]>([]);

  const onSelectChange: TableRowSelection<Manuscript>['onChange'] = (
    selectedRowKeys,
    selectedRows
  ) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedKeys(selectedRows);
  };

  useEffect(() => {
    onSelectChange([], []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  return (
    <Card
      className={'rake-filter-article-header-card'}
      title={
        <Space size="middle">
          <ManuscriptBatchDelete list={selectedRows} onSuccess={onReload} />
        </Space>
      }
      extra={
        <Space size="middle">
          <ManuscriptCustomColumn />
          <ManuscriptExport ids={selectedRowKeys as number[]} />
        </Space>
      }
    >
      <FilterTable
        columns={columns}
        rowKey="id"
        dataSource={dataSource}
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange
          // getCheckboxProps: (record) => ({
          //   disabled: record.quoteStatus !== QuoteStatus.Bidding
          // })
        }}
        // 开启横向滚动
        scroll={{ x: 1400 }}
        pagination={{ pageSizeOptions: [10, 20, 50, 100, 500, 1000] }}
        {...restFilterTableProps}
      />
    </Card>
  );
}
