import { AccountStoreModel } from './account';
import { ActivateStoreModel } from './activate';
import {TalentStoreModel} from "@/types";

export * from './account';
export * from './activate';
export * from './talent';

export interface StoreModel {
  /** 账户 */
  account: AccountStoreModel;
  /** 激活 */
  activate: ActivateStoreModel;
  /** 达人分析 */
  talent: TalentStoreModel;

}
