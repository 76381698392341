import {Button} from 'antd';
import {ConfirmButton} from '@/components';

import {useContext} from 'react';
import {deleteScriptPropertyApi} from '@/api/talent/property';
import {GroupsContext} from './context';


import {ManuscriptsGroupsListItem} from '@/types/talent/scripts';

import {EditorType} from '@/types';

interface ManuscriptsGroupsListActionProps {
    row: ManuscriptsGroupsListItem;
    onReload?: () => void;
}

export default function GroupsListAction({
                                             row,
                                             onReload
                                         }: ManuscriptsGroupsListActionProps) {
    const {groupsEditorRef, groupsType, groupsLabel} =
        useContext(GroupsContext);

    return (
        <>
            <Button
                type="link"
                size="small"
                onClick={groupsEditorRef?.current?.open.bind(
                    null,
                    EditorType.Edit,
                    row
                )}
            >
                编辑
            </Button>
            <ConfirmButton
                danger
                actionName="删除"
                label={groupsLabel}
                content={`是否删除${row.name}？`}
                onConfirm={() => deleteScriptPropertyApi(groupsType, row.id)}
                onSuccess={onReload}
            />
        </>
    );
}
