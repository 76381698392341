import { redirectToLogin } from '@/api/utils';
import { sleepUtilNow } from '@meitu/util';
import { logout } from '@/api';
import qs from 'qs';

/**
 * 获取重定向的地址
 */
export function getRedirectUrl() {
  const { origin, pathname, search } = window.location;
  const nextSearch = qs.stringify({ redirect: `${pathname}${search}` });

  return `${origin}?${nextSearch}`;
}

/**
 * 退出登录
 */
export const onLogout = function () {
  return new Promise<void>(async (resolve) => {
    const beforeAsyncTimestamp = +new Date();

    try {
      await logout();
      await sleepUtilNow(beforeAsyncTimestamp);
      redirectToLogin();
    } finally {
      resolve();
    }
  });
};
