/**
 * 点击后弹出确认窗口的按钮
 */
import { ReactNode } from 'react';
import { Button, Modal, message, ButtonProps } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface ConfirmButtonButtonProps<Payload>
  extends Omit<ButtonProps, 'title' | 'onClick'> {
  payload?: Payload;

  /** 操作名称。影响按钮内容、默认的弹窗标题、默认的弹窗内容、操作成功提示。 */
  actionName: string;

  /** 功能名称。影响默认的弹窗标题、操作成功提示。默认为 ‘’。 */
  label: string;

  /** 自定义弹窗标题。默认为 `${actionName}${label}`。 */
  title?:
    | string
    | ReactNode
    | ((
        actionName: string,
        payload?: Payload
      ) => string | ReactNode | undefined);

  /** 自定义弹窗提示内容。默认为 `确定${actionName}吗？`。 */
  content?:
    | string
    | ReactNode
    | ((
        actionName: string,
        payload?: Payload
      ) => string | ReactNode | undefined);

  /** 自定义操作成功提示内容。默认为 `${label}${actionName}成功`。 */
  successMsg?: string;

  /** 确认操作的回调 */
  onConfirm(payload?: Payload): Promise<any>;

  /** 操作成功之后的回调 */
  onSuccess?(payload?: Payload): void;

  /** 操作失败之后的回调 */
  onFail?(err: any, payload?: Payload): void;

  /**取消操作的回调 */
  onCancel?(payload?: Payload): void;
}

export default function ConfirmButton<Payload>({
  payload,
  actionName,
  label,
  title,
  content,
  successMsg,
  onConfirm,
  onSuccess,
  onFail,
  onCancel,
  ...buttonProps
}: ConfirmButtonButtonProps<Payload>) {

  const onClick = () => {
    Modal.confirm({
      title:
        (typeof title === 'function' ? title(actionName, payload) : title) ??
        `${actionName}${label}`,
      icon: <ExclamationCircleOutlined />,
      content:
        (typeof content === 'function'
          ? content(actionName, payload)
          : content) ?? `确定${actionName}吗？`,
      onOk: async () => {
        try {
          await onConfirm(payload);
          message.success(successMsg ?? `${label}${actionName}成功`);
          onSuccess?.(payload);
        } catch (err) {
          onFail?.(err, payload);
        }
      },
      onCancel() {
        onCancel?.(payload);
      }
    });
  };

  return (
    <Button type="link" size="small" onClick={onClick} {...buttonProps}>
      {actionName}
    </Button>
  );
}

ConfirmButton.defaultProps = {
  label: ''
};
