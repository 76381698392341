import { useState, useRef } from 'react';
import { Dropdown, Menu, Button } from 'antd';
import { DownOutlined, LockOutlined, LogoutOutlined } from '@ant-design/icons';
import { ResetPwdModal, ResetPwdModalRef } from '@/components';
import { useAccountProfile } from '@/hooks';
import { onLogout } from '@/utils';

function getHourLabel(hour: number) {
  if (hour > 6 && hour <= 12) {
    return '早上';
  } else if (hour <= 18) {
    return '下午';
  } else {
    return '晚上';
  }
}

interface AccountDropdownProps {
  className?: string;
}

/**
 * 账户下拉框
 * @param props
 * @constructor
 */
export default function AvatarDropdown(props: AccountDropdownProps) {
  const resetPwdModalRef = useRef<ResetPwdModalRef>(null);
  const { name } = useAccountProfile();
  const [loading, setLoading] = useState(false);

  /**
   * 重置密码
   */
  const showPswEditor = () => {
    resetPwdModalRef?.current?.open();
  };

  /**
   * 退出登录
   * NOTE: 退出登录后自动跳转到登录页面
   */
  const logout = async () => {
    setLoading(true);
    await onLogout();
    setLoading(false);
  };

  const dropDownMenu = (
    <Menu>
      <Menu.Item onClick={showPswEditor} key="resetPsw">
        <Button type="link" icon={<LockOutlined />} loading={loading}>
          密码重置
        </Button>
      </Menu.Item>
      <Menu.Item onClick={logout} key="logout">
        <Button type="link" icon={<LogoutOutlined />} loading={loading}>
          退出系统
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={dropDownMenu} placement="bottomLeft">
        <span className={props.className}>
          &nbsp;&nbsp;&nbsp;
          {getHourLabel(new Date().getHours())}好，{name || '-'}
          &nbsp;&nbsp;&nbsp;
          <DownOutlined />
        </span>
      </Dropdown>
      {/* 重置密码弹窗 */}
      <ResetPwdModal ref={resetPwdModalRef} />
    </>
  );
}
