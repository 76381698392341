import _ from 'lodash';
import { SiderAuthMenuItem, PlatformType, AdType } from '@/types';
import { ALL_APP_ID } from '@/constants';

export function flatMenuTreeData(
  treeData: SiderAuthMenuItem[]
): SiderAuthMenuItem[] {
  if (!treeData) return [];
  let list: SiderAuthMenuItem[] = [];
  treeData.forEach((item, i) => {
    list.push(item);
    const childObj = item.children || null;
    if (childObj && Array.isArray(childObj) && childObj.length > 0) {
      const childList = flatMenuTreeData(childObj);
      list = list.concat(childList);
    }
  });
  return list;
}

export function getFistTreeNode(
  tree?: SiderAuthMenuItem[]
): SiderAuthMenuItem | undefined {
  const treeItem = _.first(tree);
  if (_.isEmpty(treeItem?.children)) {
    return treeItem;
  }
  return getFistTreeNode(treeItem?.children);
}

export function findTreeNode(
  tree: SiderAuthMenuItem[] | undefined,
  key: keyof SiderAuthMenuItem,
  value: any
): SiderAuthMenuItem | undefined {
  if (_.isUndefined(tree)) {
    return undefined;
  }
  let findNode = undefined;
  tree.find((node) => {
    if (_.isEqual(node[key], value)) {
      findNode = node;
      return true;
    } else {
      const childNode = findTreeNode(node?.children, key, value);
      if (!_.isUndefined(childNode)) {
        findNode = childNode;
        return true;
      }
    }
    return false;
  });
  return findNode;
}
