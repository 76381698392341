import { RouteItem } from '@meitu/rake';
import Login from '@/containers/Login';
import Main from '@/containers/Main';

import AppError from '@/AppError';

const globalRoutes: RouteItem[] = [
  {
    path: '/login',
    title: 'login',
    component: Login
  },
  {
    path: '/error',
    title: 'error',
    component: AppError
  },
  {
    path: '/main',
    title: 'main',
    component: Main
  }
];

export default globalRoutes;
