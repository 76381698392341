import { Descriptions } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import styles from './index.module.less';

interface TipItem {
  label: string;
  desc: object;
}

interface UploadDescProps {
  title?: string;
  list: TipItem[];
}

export default function UploadDesc({ title, list }: UploadDescProps) {
  return (
    <Descriptions
      title={
        title ?? (
          <>
            <InfoCircleOutlined /> 导入说明
          </>
        )
      }
      column={1}
      className={styles.descriptions}
    >
      {list?.map(({ label, desc }) => (
        <Descriptions.Item label={label} key={`${label}_${desc}`}>
          {desc}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
}
