/**
 * 下载导出文件
 * @param url
 * @param fileName
 */
export function downloadFile(url: string, fileName: string | boolean = true) {
  const el = document.createElement('a');
  el.download = `${fileName}`;
  el.href = url;
  document.body.appendChild(el);
  el.click();
  el.remove();
}

/**
 * 下载模板
 * @param template
 * @param fileName
 * @return {undefined}
 */
export function downloadTempFile(template: string, fileName: string) {
  const blob = new Blob([template], {
    type: 'text/plain'
  });
  const fileUrl = window.URL.createObjectURL(blob);
  downloadFile(fileUrl, fileName);
  window.URL.revokeObjectURL(fileUrl);
}
