import { createInstance } from '@/api/utils';
import { ListApiResponse, ActivateItem, ActivateFilterValues } from '@/types';
import {
  transformGetArrayParams,
  transformRangeToStartEndObj
} from '@meitu/rake';

const baseURL = '/api';
const instance = createInstance({ baseURL });

/**
 * 获取激活概况查询数据
 * @description {@link https://api-mock.meitu-int.com/project/1793/interface/api/89000}
 */
export function getActivateOverviewList(filters: ActivateFilterValues) {
  const [appId, platform] = filters.app?.split('-') ?? [];
  return instance
    .get<void, ListApiResponse<ActivateItem>>('/activate', {
      params: transformGetArrayParams({
        ...transformRangeToStartEndObj(filters.rangeDate),
        appId,
        platform,
        typeIds: filters.typeIds,
        ids: filters.ids
      })
    })
    .then(({ list }) => list);
}

/**
 * 激活概况列表导出
 */
export const EXPORT_ACTIVATE_OVERVIEW_URL = `${baseURL}/activate/export`;
