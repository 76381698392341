import {
  FetchableSelect,
  FetchableSelectProps,
  FetchableSelectRef,
  useWatch
} from '@meitu/rake';

import { useMemo, useRef } from 'react';
import { useStoreActions, useStoreState } from '@/store';

import { CustomOptionsApiResponse, OptionsApiResponse } from '@/types';
import { ManuscriptsGroupsType } from '@/types/talent/scripts';
import {MANUSCRIPTS_GROUPS_MAP} from "@/constants/talent/manuscripts";
import {ALL_OPTION_VALUE_STRING} from "@/constants";

interface ContentTypeSelectProps<ValueType>
  extends Omit<FetchableSelectProps<ValueType>, 'onFetch' | 'options'> {
  /** 关联类型 */
  manuscriptsGroupsType: ManuscriptsGroupsType;
  /** 切换应用后重置选中数据值 */
  resetValue?: ValueType;
}

/**
 * 稿件互动明细-媒体类型下拉选择器
 */
export default function ContentTypeSelect<ValueType = string | string[]>({
  manuscriptsGroupsType,
  resetValue,
  ...restProps
}: ContentTypeSelectProps<ValueType>) {
  const fetchAbleSelectRef = useRef<FetchableSelectRef<ValueType>>(null);

  const contentTypeList = useStoreState(
    (state) => state.talent.scriptKoc.contentTypeList
  );
  let dataSource: CustomOptionsApiResponse[] = contentTypeList;

  const fetchContentTypeList = useStoreActions(
    (actions) => actions.talent.scriptKoc.fetchContentTypeList
  );

  const options = useMemo<FetchableSelectProps<ValueType>['options']>(
    () =>
      dataSource.map(({ id, desc }) => ({
        value: id,
        label: desc
      })),
    [dataSource]
  );

  /** 应用变化、类型变化时刷新下拉列表 */
  useWatch([manuscriptsGroupsType], (prev) => {
    fetchAbleSelectRef?.current?.resetOptions(resetValue);
  });
  const label = MANUSCRIPTS_GROUPS_MAP.get(manuscriptsGroupsType);

  return (
    <FetchableSelect
      ref={fetchAbleSelectRef}
      testKey={label}
      placeholder={`请选择${label}`}
      allOptionLabel={`全部${label}`}
      allOptionValue={ALL_OPTION_VALUE_STRING}
      options={options}
      onFetch={() => fetchContentTypeList()}
      {...restProps}
    />
  );
}
