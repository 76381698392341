import {Col, Form} from 'antd';

import {FetchableSelectProps, FilterFormProps} from '@meitu/rake';
import {
    ManuscriptsGroupsType,
    ScriptsFilterValues
} from '@/types/talent/scripts';
import {ManuscriptsAppSelect} from '@/components';
import MediaTypeSelect from '@/components/talent/MediaTypeSelect';
import ContentTypeSelect from '@/components/talent/ContentTypeSelect';
import ManuscriptsGroupsSelect from '@/components/talent/ManuscriptsGroupsSelect';

interface ScriptsFilterFormGroupProps
    extends Omit<FilterFormProps<ScriptsFilterValues>, 'children'> {
    initialFilterValues: ScriptsFilterValues;
    selectProps?: FetchableSelectProps<number | number[] | string | string[] | undefined>;
}

function AppIds({
                    initialFilterValues,
                    colProps,
                    selectProps
                }: ScriptsFilterFormGroupProps) {
    return (
        <Col {...colProps}>
            <Form.Item
                className={'rake-fetchable-select-wrap'}
                label="应用"
                name="appIds"
            >
                <ManuscriptsAppSelect
                    manuscriptsGroupsType={ManuscriptsGroupsType.Apps}
                    hasAll
                    mode="multiple"
                    resetValue={initialFilterValues.appIds}
                    forceFetch
                    {...selectProps}
                />
            </Form.Item>
        </Col>
    );
}

function MediaTypeIds({
                          initialFilterValues,
                          colProps,
                          selectProps
                      }: ScriptsFilterFormGroupProps) {
    return (
        <Col {...colProps}>
            <Form.Item
                className={'rake-fetchable-select-wrap'}
                label="媒体类型"
                name="mediaTypeIds"
            >
                <MediaTypeSelect
                    manuscriptsGroupsType={ManuscriptsGroupsType.MediaTypes}
                    hasAll
                    mode="multiple"
                    resetValue={initialFilterValues.mediaTypeIds}
                    forceFetch
                    {...selectProps}
                />
            </Form.Item>
        </Col>
    );
}

function ContentTypeIds({
                            initialFilterValues,
                            colProps,
                            selectProps
                        }: ScriptsFilterFormGroupProps) {
    return (
        <Col {...colProps}>
            <Form.Item
                className={'rake-fetchable-select-wrap'}
                label="内容类型"
                name="contentTypeIds"
            >
                <ContentTypeSelect
                    manuscriptsGroupsType={ManuscriptsGroupsType.ContentTypes}
                    hasAll
                    mode="multiple"
                    resetValue={initialFilterValues.contentTypeIds}
                    forceFetch
                    {...selectProps}
                />
            </Form.Item>
        </Col>
    );
}

function LabelIds({
                      initialFilterValues,
                      colProps,
                      selectProps
                  }: ScriptsFilterFormGroupProps) {
    return (
        <Col {...colProps}>
            <Form.Item
                className={'rake-fetchable-select-wrap'}
                label="标签"
                name="labelIds"
            >
                <ManuscriptsGroupsSelect
                    placeholder="请选择标签"
                    groupsType={ManuscriptsGroupsType.Labels}
                    hasAll
                    mode="multiple"
                    resetValue={initialFilterValues.labelIds}
                    forceFetch
                    {...selectProps}
                />
            </Form.Item>
        </Col>
    );
}

export default function ScriptsFilterFormGroup(
    props: ScriptsFilterFormGroupProps
) {
    return (
        <>
            {/* 应用 */}
            <AppIds {...props} />
            {/* 媒体类型 */}
            <MediaTypeIds {...props} />
            {/* 内容类型 */}
            <ContentTypeIds {...props} />
            {/* 标签 */}
            <LabelIds {...props} />
        </>
    );
}

ScriptsFilterFormGroup.AppIds = AppIds;
ScriptsFilterFormGroup.MediaTypeIds = MediaTypeIds;
ScriptsFilterFormGroup.ContentTypeIds = ContentTypeIds;
ScriptsFilterFormGroup.LabelIds = LabelIds;
