import ManuscriptListAction from './ManuscriptListAction';

import { useMemo } from 'react';
import { useStoreState } from '@/store';
import { toMoment } from '@meitu/util';

import { TableColumn } from '@meitu/rake';
import { Manuscript } from '@/types/talent/scripts';
import typographyStyle from '@/styles/typography.module.less';
import TruncatedText from "@/components/talent/TruncatedText";

export default function useColumns(onReload?: () => void) {
  const customColumns = useStoreState(
    (state) => state.talent.scriptKoc.customColumns
  );

  return useMemo(() => {
    const leftColumns: TableColumn<Manuscript>[] = [
      {
        dataIndex: 'index',
        title: '序号',
        width: 40,
        fixed: 'left',
        align: 'center'
      },
      {
        title: '封面',
        dataIndex: 'coverPath',
        width: 110,
        align: 'center',
        fixed: 'left',
        render: (val, row) => {
          return typeof val === 'string' && val.trim().length === 0 ? (
              null
          ) : (
              <img
                  src={`/api/script/cover?id=${row.id}`}
                  style={{width:'100px'}}
              ></img>
          );
        }
      },
      {
        title: '稿件标题',
        dataIndex: 'title',
        width: 80,
        align: 'center',
        fixed: 'left',
        render: (val, raw) => {
          return typeof val === 'string' && val.trim().length === 0 ? (
              <a
                  className={typographyStyle.linkLabel}
                  href={raw.link}
                  target={'_blank'}
                  rel="noreferrer"
              >
                <TruncatedText url={raw.link} />
              </a>
          ) : (
              <a
                  className={typographyStyle.linkLabel}
                  href={raw.link}
                  target={'_blank'}
                  rel="noreferrer"
              >
                <TruncatedText url={val} />
              </a>
          );
        }
      },
      {
        title: '稿件审核链接',
        dataIndex: 'auditLink',
        width: 100,
        align: 'center',
        fixed: 'left',
        render: (val) => {
          return typeof val === 'string' && val.trim().length === 0 ? (
            val
          ) : (
            <a
              className={typographyStyle.linkLabel}
              href={val}
              target={'_blank'}
              rel="noreferrer"
            >
              <TruncatedText url={val} />
            </a>
          );
        }
      },
      {
        dataIndex: 'expertName',
        title: '达人名称',
        width: 70,
        fixed: 'left',
        align: 'center'
      },
      {
        dataIndex: 'strikePrice',
        title: '执行价格',
        width: 70,
        fixed: 'left',
        align: 'center'
      }
    ];

    const rightCommonColumns: TableColumn<Manuscript>[] = [
      {
        dataIndex: 'createTime',
        title: '添加时间',
        width: 80,
        align: 'center',
        render: (val) => toMoment(val)?.format('YYYY-MM-DD') ?? '-'
      },
      // NOTE: 操作列中有查看功能，因此不需要判断权限。
      {
        key: 'actions',
        title: '操作',
        width: 120,
        fixed: 'right',
        align: 'center',
        render: (actions, row) => (
          <ManuscriptListAction row={row} onReload={onReload} />
        )
      }
    ];

    const columns: TableColumn<Manuscript>[] = [
      ...leftColumns,
      // 自定义渠道信息列
      ...customColumns,
      ...rightCommonColumns
    ];

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customColumns]);
}
