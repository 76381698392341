import {forwardRef, useEffect} from 'react';
import {RakeLayout} from '@meitu/rake';
import {
    manuscriptGroupsRouteItem,
    manuscriptGroupsTabsPanes,
    useManuscriptGroupsRoute
} from '@/routes/talent/groups';
import {PageTabs} from '@/components';
import _ from 'lodash';
import {useHistory} from 'react-router-dom';
import {useStoreState} from '@/store';
import {useGetRouteByName} from "@/hooks/router";

export {default as Labels} from './Labels';

export default forwardRef<HTMLDivElement>(function Groups(props, ref) {
    const authMenuTreeFirstRoute = useStoreState(
        (state) => state.account.authMenuTreeFirstRoute
    );
    const manuscriptGroupsRoute = useManuscriptGroupsRoute();
    const shouldRedirect = !_.size(manuscriptGroupsRoute?.children);
    const getRouteByName = useGetRouteByName();
    const history = useHistory();
    useEffect(
        () => {
            shouldRedirect && history.replace(getRouteByName(authMenuTreeFirstRoute));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [shouldRedirect]
    );
    return (
        <RakeLayout.RoutesContent
            ref={ref}
            header={<PageTabs tabsPanes={manuscriptGroupsTabsPanes}/>}
            routeItem={manuscriptGroupsRouteItem}
        />
    );
});
