import { forwardRef, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import routes from '@/routes';
import { useAppTransitionKey } from '@/hooks';
import { NotFound, RakeLayout, useFadeClassNames } from '@meitu/rake';
import { MainContainer } from '@/containers/Layout';
import {Sider} from "@/containers/Layout/Sider";
import '@/styles/global.less';
import layoutStyles from '@/styles/layout.module.less';
const basicRoutes = routes.map(({ path }) => path);

export default forwardRef<HTMLDivElement>(function App(props, ref) {
  const nodeRef = useRef<HTMLDivElement>(null);
  const transitionKey = useAppTransitionKey();
  const fadeClassNames = useFadeClassNames();

  return (
    <div ref={ref} id="app">
      <TransitionGroup component={null}>
        <CSSTransition
          key={transitionKey}
          classNames={fadeClassNames}
          timeout={300}
          nodeRef={nodeRef}
        >
          <Switch>
            <Route path={basicRoutes}>
              <RakeLayout
                  ref={nodeRef}
                  siderWidth={218}
                  theme="dark"
                  className={layoutStyles.layout}>
                <Sider />
                <MainContainer />
              </RakeLayout>
            </Route>
            <Route path="*">
              <NotFound ref={nodeRef} />
            </Route>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
});
