import { ReactNode, useState } from 'react';
import { Button, Tooltip, ButtonProps } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import _ from 'lodash';
import { useIsMountedRef } from '@meitu/rake';
import { exportFileApi } from '@/api';

interface ExportButtonProps extends ButtonProps {
  icon?: ReactNode;
  url?: string | (() => string);
  params?: Record<string, any> | (() => Record<string, any>);
  fileTitle?: string | (() => string);
}

export function CommonExportButton({
  title = '导出',
  icon = <DownloadOutlined />,
  url,
  params,
  fileTitle,
  type = 'default',
  ...restProps
}: ExportButtonProps) {
  const isMounted = useIsMountedRef();
  const [loading, setLoading] = useState(false);

  return (
    <Tooltip title={title}>
      <Button
        type={type}
        shape="circle"
        icon={icon}
        loading={loading}
        onClick={() => {
          const fileUrl = _.isFunction(url) ? url() : url;
          if (fileUrl) {
            setLoading(true);
            exportFileApi(fileUrl, _.isFunction(params) ? params() : params, {
              fileName: _.isFunction(fileTitle) ? fileTitle() : fileTitle
            }).finally(() => {
              if (!isMounted.current) return;
              setLoading(false);
            });
          }
        }}
        {...restProps}
      />
    </Tooltip>
  );
}
