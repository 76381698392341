import React, {forwardRef, useEffect} from 'react';

import styles from './index.module.less';
import {useStoreState} from "@/store";
import {useGetRouteByName} from "@/hooks/router";
import {useHistory} from "react-router-dom";

export default forwardRef<HTMLDivElement>(function Main(props, ref) {
    const authMenuTreeFirstRoute = useStoreState(
        (state) => state.account.authMenuTreeFirstRoute
    );
    const getRouteByName = useGetRouteByName();
    const history = useHistory();

    useEffect(
        () => {
            history.replace(getRouteByName(authMenuTreeFirstRoute));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );


    return (
        <div className={styles.loginPage}>
        </div>
    );
});
