import {DatePicker, Divider, Form, InputNumber, message} from 'antd';
import {catchFormError, ScrollModal, TrimInput} from '@meitu/rake';
import {ManuscriptsAppSelect} from '@/components';

import {forwardRef, useContext, useImperativeHandle, useState} from 'react';
import _ from 'lodash';
import {ManuscriptContext} from './context';
import {DEFAULT_MODAL_FORM_COL} from '@/constants';
import moment from 'moment';

import {EditorType} from '@/types';
import {useStoreActions} from '@/store';
import {Manuscript, ManuscriptFormValues, ManuscriptsGroupsType} from "@/types/talent/scripts";
import {addScriptApi, updateScriptApi} from "@/api/talent/script";
import MediaTypeSelect from "@/components/talent/MediaTypeSelect";
import ContentTypeSelect from "@/components/talent/ContentTypeSelect";
import ManuscriptLabelsSelect from "@/components/talent/ManuscriptLabelsSelect";
import ManuscriptsGroupsSelect from "@/components/talent/ManuscriptsGroupsSelect";

const DEFAULT_FORM_VALUES = {
    link: '',
    auditLink: '',
    mediaType: '',
    contentType: '',
    agreedTime: 0,
    expertName: '',
    expertWechatName: '',
    expertUid: '',
    appId: 0,
    appName: '',
    strikePrice: 0.0,
    supplierId: 0,
    labelIds: [],
    labels: [],
    agreedTimeStr: '',
};

export interface ManuscriptEditorRef {
    open(type: EditorType.Add): void;

    open(type: EditorType, row?: Manuscript): void;
}

export interface ManuscriptEditorProps {
    onChange?: () => void;
}

/**
 * 渠道管理-渠道编辑弹窗
 * TODO: 组件内容过多，需要拆分
 */
export default forwardRef<ManuscriptEditorRef, ManuscriptEditorProps>(
    function ManuscriptEditor(props, ref) {
        const {manuscriptEditorRef} = useContext(ManuscriptContext);
        const fetchGroupsOptions = useStoreActions(
            (actions) => actions.talent.scriptKoc.fetchGroupsOptions
        );
        const [form] = Form.useForm<ManuscriptFormValues>();
        const [type, setType] = useState<EditorType>(EditorType.Add);
        const [visible, setVisible] = useState(false);
        const [submitting, setSubmitting] = useState(false);
        const [manuscript, setManuscript] = useState<Manuscript>();
        const [currentLink, setCurrentLink] = useState<Manuscript['link']>();
        const [currentAuditLink, setCurrentAuditLink] =
            useState<Manuscript['auditLink']>();
        const [currentContentType, setCurrentContentType] =
            useState<Manuscript['contentType']>();
        const [currentMediaType, setCurrentMediaType] =
            useState<Manuscript['mediaType']>();
        const [currentAgreedTime, setCurrentAgreedTime] =
            useState<Manuscript['agreedTime']>();
        const [currentAgreedTimeStr, setCurrentAgreedTimeStr] =
            useState<Manuscript['agreedTimeStr']>();
        const [currentExpertName, setCurrentExpertName] =
            useState<Manuscript['expertName']>();
        const [currentExpertWechatName, setCurrentExpertWechatName] =
            useState<Manuscript['expertWechatName']>();
        const [currentExpertUid, setCurrentExpertUid] =
            useState<Manuscript['expertUid']>();
        const [currentAppId, setCurrentAppId] =
            useState<Manuscript['appId']>();
        const [currentStrikePrice, setCurrentStrikePrice] =
            useState<Manuscript['strikePrice']>();
        const [supplierId, setSupplierId] =
            useState<Manuscript['supplierId']>();

        const [agreedTimeDate, setAgreedTimeDate] = useState(moment(currentAgreedTimeStr));

        const isAddType = type === EditorType.Add;
        const isEditType = type === EditorType.Edit;


        const onSubmit = async (formValues: ManuscriptFormValues) => {
            setSubmitting(true);

            try {
                const {labelIds} = formValues;
                const labels: any[] = []

                labelIds?.forEach((item) => {
                    if (typeof item == 'string') {
                        labels.push({'name': item})
                    } else {
                        labels.push({'id': item})
                    }
                });


                const params = _.defaults(
                    {
                        ...formValues,
                        labels: !_.isEmpty(labels)
                            ? labels
                            : DEFAULT_FORM_VALUES.labels,
                        agreedTime: currentAgreedTime,
                        supplierId,
                    },
                    DEFAULT_FORM_VALUES
                );

                if (params['agreedTime'] == 0) {
                    params['agreedTime'] = parseInt(moment().format('YYYYMMDD'))
                }

                switch (type) {
                    case EditorType.Add: {
                        await addScriptApi(params);
                        message.success('稿件添加成功');
                        break;
                    }
                    case EditorType.Edit: {
                        await updateScriptApi(manuscript?.id as number, params);
                        message.success('稿件修改成功');
                        break;
                    }
                }
                props.onChange?.();
                setVisible(false);
            } finally {
                await fetchGroupsOptions({groupsType: ManuscriptsGroupsType.Labels});
                setSubmitting(false);
            }
        };

        useImperativeHandle(ref, () => ({
            open: (type: EditorType, row?: Manuscript) => {
                setType(type);
                setVisible(true);
                setManuscript(row);
                setCurrentLink(row?.link ?? DEFAULT_FORM_VALUES.link);
                setCurrentAuditLink(row?.auditLink ?? DEFAULT_FORM_VALUES.auditLink);
                setCurrentMediaType(row?.mediaType ?? DEFAULT_FORM_VALUES.mediaType);
                setCurrentContentType(row?.contentType ?? DEFAULT_FORM_VALUES.contentType);
                setCurrentAgreedTime(row?.agreedTime ?? DEFAULT_FORM_VALUES.agreedTime);
                setCurrentExpertName(row?.expertName ?? DEFAULT_FORM_VALUES.expertName);
                setCurrentExpertUid(row?.expertUid ?? DEFAULT_FORM_VALUES.expertUid);
                setCurrentExpertWechatName(row?.expertWechatName ?? DEFAULT_FORM_VALUES.expertWechatName);
                setCurrentAppId(row?.appId ?? DEFAULT_FORM_VALUES.appId);
                setCurrentStrikePrice(row?.strikePrice ?? DEFAULT_FORM_VALUES.strikePrice);
                setAgreedTimeDate(moment(row?.agreedTimeStr));
                setSupplierId(row?.supplierId ?? DEFAULT_FORM_VALUES.supplierId);
            }
        }));

        const modelTitle = (
            isAddType: boolean,
            isEditType: boolean
        ) => {
            if (isAddType) {
                return '添加稿件';
            }
            if (isEditType) {
                return '编辑稿件';
            }
        };

        const onDateChange = (dateStr: string) => {
            const [year, month, day] = dateStr.split('-');
            const numberStr = `${year}${month}${day}`;
            setAgreedTimeDate(moment(dateStr))
            if (manuscript != undefined) {
                setCurrentAgreedTime(parseInt(numberStr));
                setCurrentAgreedTimeStr(dateStr)
            } else {
                DEFAULT_FORM_VALUES.agreedTime = parseInt(numberStr);
                DEFAULT_FORM_VALUES.agreedTimeStr = dateStr;
            }
        };


        return (
            <ScrollModal
                width={650}
                visible={visible}
                title={modelTitle(isAddType, isEditType)}
                maskClosable={false}
                okButtonProps={{loading: submitting}}
                destroyOnClose
                onOk={() =>
                    form.validateFields().then(onSubmit).catch(catchFormError)
                }
                onCancel={() => setVisible(false)}
                afterClose={() => form.resetFields()}
            >
                <Form {...DEFAULT_MODAL_FORM_COL} form={form}>
                    <Divider dashed/>
                    <Form.Item
                        label="稿件链接"
                        name="link"
                        initialValue={currentLink}
                        rules={
                            isAddType
                                ? [
                                    {required: true, message: '请输入稿件链接'},
                                ]
                                : undefined
                        }
                    >
                        {isAddType ? (
                            <TrimInput
                                placeholder="请输入稿件链接"
                                onChange={({target: {value}}) => {
                                    setCurrentLink(value);
                                    //渠道名称跟随渠道标识复制联动
                                    form.setFieldsValue({link: value});
                                }}
                            />
                        ) : (
                            <code>{manuscript?.link}</code>
                        )}
                    </Form.Item>
                    <Form.Item
                        label="稿件审核链接"
                        name="auditLink"
                        initialValue={manuscript?.auditLink}
                    >
                        <TrimInput placeholder="请输入稿件审核链接"/>
                    </Form.Item>

                    <Form.Item
                        label="媒体类型"
                        name="mediaType"
                        className={'rake-fetchable-select-wrap'}
                        // NOTE: 旧数据获客来源存在“未分组”（id：0），强制转换为“推广量”
                        initialValue={manuscript?.mediaType}
                        rules={[
                            {required: true, message: '请选择媒体类型'},
                        ]}
                    >
                        <MediaTypeSelect
                            manuscriptsGroupsType={ManuscriptsGroupsType.MediaTypes}
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                        />
                    </Form.Item>

                    <Form.Item
                        label="内容形式"
                        name="contentType"
                        // NOTE: 旧数据获客来源存在“未分组”（id：0），强制转换为“推广量”
                        initialValue={manuscript?.contentType}
                        rules={[
                            {required: true, message: '请选择内容形式'},
                        ]}
                    >
                        <ContentTypeSelect
                            manuscriptsGroupsType={ManuscriptsGroupsType.ContentTypes}
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                        />
                    </Form.Item>
                    <Form.Item
                        label="约稿日期"
                        rules={[
                            {required: true, message: '请选择约稿日期'},
                        ]}
                    >
                        <DatePicker
                            value={agreedTimeDate}
                            placeholder='请选择约稿日期'
                            onChange={(value, string) => onDateChange(string)}
                        />
                    </Form.Item>
                    <Form.Item
                        label="标签"
                        name="labelIds"
                        initialValue={
                            _.isEmpty(manuscript?.labels)
                                ? isEditType
                                    ? DEFAULT_FORM_VALUES.labelIds
                                    : []
                                : _.map(manuscript?.labels, 'id')
                        }
                    >
                        <ManuscriptLabelsSelect
                            groupsType={ManuscriptsGroupsType.Labels}
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                        />
                    </Form.Item>

                    <Form.Item
                        label="达人名称"
                        name="expertName"
                        initialValue={manuscript?.expertName}
                    >
                        <TrimInput placeholder="请输入达人名称"/>
                    </Form.Item>

                    <Form.Item
                        label="达人微信名称"
                        name="expertWechatName"
                        initialValue={manuscript?.expertWechatName}
                    >
                        <TrimInput placeholder="请输入达人微信名称"/>
                    </Form.Item>

                    <Form.Item
                        label="uid"
                        name="expertUid"
                        initialValue={manuscript?.expertUid}
                    >
                        <TrimInput placeholder="请输入达人uid"/>
                    </Form.Item>

                    <Form.Item
                        label="应用"
                        name="appId"
                        // NOTE: 旧数据获客来源存在“未分组”（id：0），强制转换为“推广量”
                        initialValue={manuscript?.appId}
                        rules={[
                            {required: true, message: '请选择应用信息'},
                        ]}
                    >
                        <ManuscriptsAppSelect
                            manuscriptsGroupsType={ManuscriptsGroupsType.Apps}
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                        />
                    </Form.Item>
                    <Form.Item
                        label="执行价格"
                        name="strikePrice"
                        initialValue={manuscript?.strikePrice || DEFAULT_FORM_VALUES.strikePrice}
                        rules={[
                            {required: true, message: '请输入执行价格'},
                        ]}
                    >
                        <InputNumber
                            step={1}
                            min={0}
                            placeholder="请输入执行价格"
                        />
                    </Form.Item>

                </Form>
            </ScrollModal>
        );
    }
);
