import {RouteItem} from '@meitu/rake';
import {useMemo} from 'react';
import _ from 'lodash';
import Manuscript from "@/containers/Talent/Manuscript";
import ManuscriptDetail from "@/containers/Talent/Manuscript/ManuscriptDetail";

/**
 * 一级菜单路由
 */
const FIRST_MENU_PATH = 'talent';

/**
 * 二级菜单路由
 * NOTE: RakeLayout.RoutesContent 组件除顶级路由外，path 均不需要加’/‘。
 */

const settingManuscriptRoute: RouteItem = {
    path: 'script',
    title: 'KOC稿件管理',
    component: Manuscript,
    children: [
        {
            path: 'list',
            title: '稿件列表',
            component: ManuscriptDetail
        }
    ]
};

/**
 * 补充完整 path。用于定义独立的 RakeLayout.RoutesContent routeItem
 */
export const settingManuscriptRouteItem = {
    ...settingManuscriptRoute,
    path: `/${FIRST_MENU_PATH}/${settingManuscriptRoute.path}`
};


/* 限制在指定项目下加载路由 */
export function useSettingManuscriptRoute() {
    return {
        ...settingManuscriptRoute,
        path: `/${FIRST_MENU_PATH}/${settingManuscriptRoute.path}`,
        children: settingManuscriptRoute.children
    }
}

/**
 * 补充完整 path。用于定义选项卡内容
 */
// @ts-ignore
export const settingManuscriptTabsPanes = settingManuscriptRoute.children.map(
    ({path, title}) => ({
        path: `/${FIRST_MENU_PATH}/${settingManuscriptRoute.path}/${path}`,
        title
    })
);

export default settingManuscriptRoute;
