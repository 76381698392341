import { createInstance } from '@/api/utils';
import { saveAs } from 'file-saver';
import _ from 'lodash';

import qs from 'qs';

export type ExportFileApiOpt = {
  fileName?: string;
};

export type ExportFileApi = (
  /** 文件下载地址 */
  url: string,
  /** 文件下载参数 */
  params?: Record<string, any>,
  /**
   * 可扩展配置项。预留参数，可用于扩展 blob 解析、文件下载配置等
   */
  opts?: ExportFileApiOpt
) => Promise<any>;

const axiosInstance = createInstance({ baseURL: '' });

/**
 * 通用文件导出接口
 */
export const exportFileApi: ExportFileApi = (url, params, opts) =>
  axiosInstance
    .get<void, any>(url, {
      params,
      responseType: 'blob'
    })
    .then((response) => {
      const { data, headers } = response;
      let fileName = opts?.fileName;
      if (!fileName) {
        // 获取接口返回的文件名
        const disposition = qs.parse(headers['content-disposition'], {
          delimiter: ';',
          charset: 'utf-8',
          charsetSentinel: true
        });
        fileName = _.find(
          disposition,
          (val, key) => _.trim(key) === 'filename'
        ) as string;
      }
      saveAs(new Blob([data]), fileName);
      return data;
    });
