import { FilterTable, FilterTableProps, TableColumn } from '@meitu/rake';
import GroupsListAction from './GroupsListAction';

import { useContext } from 'react';
import { toMoment } from '@meitu/util';
import { GroupsContext } from './context';
import { ManuscriptsGroupsListItem } from '@/types/talent/scripts';

interface ManuscriptGroupsListProps
  extends FilterTableProps<ManuscriptsGroupsListItem> {
  onReload?(): void;
}

export default function GroupsList({
  onReload,
  ...restFilterTableProps
}: ManuscriptGroupsListProps) {
  const { groupsLabel } = useContext(GroupsContext);

  const columns: TableColumn<ManuscriptsGroupsListItem>[] = [
    {
      dataIndex: 'index',
      title: '序号',
      width: 80,
      align: 'center'
    },
    {
      dataIndex: 'name',
      title: `${groupsLabel}名称`,
      width: 120,
      align: 'center'
    },
    {
      dataIndex: 'createUserName',
      title: '添加人',
      width: 120,
      align: 'center'
    },
    {
      dataIndex: 'createTime',
      title: '添加时间',
      width: 120,
      align: 'center',
      render: (val) => toMoment(val)?.format('YYYY-MM-DD') ?? '-'
    },
    {
      key: 'actions',
      title: '操作',
      width: 120,
      align: 'center',
      render: (actions, row) => (
          <GroupsListAction row={row} onReload={onReload} />
      )
    }
  ];

  return (
    <FilterTable columns={columns} rowKey="id" {...restFilterTableProps} />
  );
}
