import { RakeLayout } from '@meitu/rake';
import AvatarDropdown from './AvatarDropdown';
import styles from './Header.module.less';
import PrimaryMenu from "@/containers/PrimaryMenu/PrimaryMenu";

export default function Header() {
  return (
    <RakeLayout.Header>
      <main className={styles.main}>
          <section className={styles.extra}>
              <PrimaryMenu />
          </section>
        <section className={styles.extra}>
          {process.env.REACT_APP_TITLE}
        </section>
        <section className={styles.actions}>
          <AvatarDropdown className={styles.action} />
        </section>
      </main>
    </RakeLayout.Header>
  );
}
