import {AdType, SiderAuthMenuItem} from '@/types';

export const siderMenuItems: SiderAuthMenuItem[] = [
    {
        adType: AdType.CHANNEL,
        route: '/channel',
        isSubMenu: true,
        children: [
            {route: '/channel/activate'}
        ]
    },
    {
        adType: AdType.TALENT,
        route: '/talent',
        isSubMenu: true,
        children: [
            {route: '/talent/script'},
            {route: '/talent/groups'}
        ]
    },

];
