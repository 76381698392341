import {RakeLayout} from '@meitu/rake';
import {routeMatchers} from '@/routes';
import {useStoreState} from '@/store';
import styles from './Sider.module.less';

export function Sider() {
    const authMenuTree = useStoreState((state) => state.account.
        authMenuTree);
    return (
        <RakeLayout.Sider routeMatchers={routeMatchers} menuItems={authMenuTree}>
            <h1 className={styles.customH1}/>
        </RakeLayout.Sider>
    );
}
