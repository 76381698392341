import {forwardRef, useEffect} from 'react';
import {RakeLayout} from '@meitu/rake';
import {
    settingManuscriptRouteItem,
    settingManuscriptTabsPanes,
    useSettingManuscriptRoute,

} from '@/routes/talent/manuscript';
import {PageTabs} from '@/components';
import _ from 'lodash';
import {useHistory} from 'react-router-dom';
import {useStoreState} from '@/store';
import {useGetRouteByName} from "@/hooks/router";

export {default as ManuscriptDetail} from './ManuscriptDetail';

export default forwardRef<HTMLDivElement>(function ManuscriptWrapper(props, ref) {
    const authMenuTreeFirstRoute = useStoreState(
        (state) => state.account.authMenuTreeFirstRoute
    );
    const settingManuscriptRoute = useSettingManuscriptRoute();
    const shouldRedirect = !_.size(settingManuscriptRoute?.children);
    const getRouteByName = useGetRouteByName();
    const history = useHistory();

    useEffect(
        () => {
            shouldRedirect && history.replace(getRouteByName(authMenuTreeFirstRoute));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [shouldRedirect]
    );
    return (
        <RakeLayout.RoutesContent
            ref={ref}
            header={<PageTabs tabsPanes={settingManuscriptTabsPanes}/>}
            routeItem={settingManuscriptRouteItem}
        />
    );
});
