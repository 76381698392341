import {Form} from 'antd';
import {FilterForm, FilterFormProps, TrimInput} from '@meitu/rake';
import {ScriptsFilterFormGroup} from '@/components';
import {ScriptsFilterValues} from "@/types/talent/scripts";


interface ManuscriptFormProps
    extends Omit<FilterFormProps<ScriptsFilterValues>, 'children'> {
}

export default function ManuscriptForm({
                                           initialValues,
                                           ...restFilterFormProps
                                       }: ManuscriptFormProps) {
    return (
        <FilterForm
            {...restFilterFormProps}
            initialValues={initialValues}
            submitButton
            resetButton
        >
            <ScriptsFilterFormGroup
                initialFilterValues={initialValues as ScriptsFilterValues}
            />
            <Form.Item label="稿件信息" name="link">
                <TrimInput placeholder="请输入稿件标题、链接" allowClear />
            </Form.Item>
            <Form.Item label="达人信息" name="expertInfo">
                <TrimInput placeholder="请输入达人姓名或微信" allowClear/>
            </Form.Item>
            <Form.Item label="uid" name="expertUid">
                <TrimInput placeholder="请输入uid" allowClear/>
            </Form.Item>
        </FilterForm>
    );
}
