import {createContext, RefObject} from 'react';

import {MANUSCRIPTS_GROUPS_MAP} from '@/constants/talent/manuscripts';
import {ManuscriptsGroupsType} from '@/types/talent/scripts';
import {GroupsEditorRef} from './GroupsEditor';

export const GroupsContext = createContext<{
    groupsEditorRef?: RefObject<GroupsEditorRef>;
    groupsType: ManuscriptsGroupsType;
    groupsLabel: string | undefined;
}>({
    groupsType: ManuscriptsGroupsType.Labels,
    groupsLabel: MANUSCRIPTS_GROUPS_MAP.get(ManuscriptsGroupsType.Labels)
});
