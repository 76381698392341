import {Button} from 'antd';
import {ConfirmButton} from '@/components';

import {useContext} from 'react';
import {ManuscriptContext} from '../context';

import {EditorType} from '@/types';
import {Manuscript} from "@/types/talent/scripts";
import {deleteScriptApi} from "@/api/talent/script";

interface ScriptListActionProps {
    row: Manuscript;

    onReload?(): void;
}

export default function ManuscriptListAction({
                                                 row,
                                                 onReload
                                             }: ScriptListActionProps) {
    const {
        manuscriptEditorRef
    } = useContext(ManuscriptContext);

    return (
        <>
            <Button
                type="link"
                size="small"
                onClick={manuscriptEditorRef?.current?.open.bind(
                    null,
                    EditorType.Edit,
                    row
                )}
            >
                编辑
            </Button>
            <ConfirmButton
                actionName="删除"
                label="稿件"
                content={`是否删除 ${row.link}？`}
                onConfirm={() => deleteScriptApi([row.id])}
                onSuccess={onReload}
            />

        </>
    );
}
