import {Button, message} from 'antd';
import {FileAddOutlined} from '@ant-design/icons';
import {UploadDesc, UploadModal, UploadModalRef} from '@/components';

import {useRef} from 'react';
import {downloadTempFile} from '@/utils';
import {importScriptApi} from "@/api/talent/script";

const IMPORT_FILE_NAME = `稿件管理模板.csv`;
const IMPORT_FILE_TEMPLATE = `${'\ufeff'}稿件链接,稿件审核链接,媒体类型,内容形式,约稿时间,达人名称,达人微信名称,uid,应用,执行价格,标签`;
const TIP_LIST = [
    {
        label: '稿件链接',
        desc: <td>所添加的稿件对应的链接，link</td>
    },
    {
        label: '稿件审核链接',
        desc: <td>所添加的稿件对应的审核链接</td>
    },
    {
        label: '媒体类型',
        desc: <td>媒体类型，抖音 or 小红书</td>
    },
    {
        label: '内容形式',
        desc: <td>内容形式 视频 or 图片</td>
    },
    {
        label: '约稿时间',
        desc: <td>格式：20240501 数字类型</td>
    },
    {
        label: '达人名称',
        desc: <td>对应达人名称</td>
    },
    {
        label: '达人微信名称',
        desc: <td>对应达人微信名称</td>
    },
    {
        label: 'uid',
        desc: <td>对应达人uid</td>
    },
    {
        label: '应用',
        desc: <td>对应应用信息，如：美图秀秀</td>
    },
    {
        label: '执行价格',
        desc: <td>执行价格</td>
    },
    {
        label: '标签',
        desc: (
            <td>
                稿件的标签分类，可为空，若存在多个标签归属，需用中文分号（；）进行分隔
            </td>
        )
    }
];


export interface ManuscriptBatchUploadProps {
    onReload?: () => void;
}

export default function ManuscriptBatchUpload({
                                                  onReload
                                              }: ManuscriptBatchUploadProps) {
    const fileUploadRef = useRef<UploadModalRef>(null);

    let tipList = TIP_LIST;
    let template = IMPORT_FILE_TEMPLATE

    return (
        <>
            <Button
                icon={<FileAddOutlined/>}
                onClick={() => fileUploadRef.current?.open()}
            >
                批量添加
            </Button>
            <UploadModal
                title={'批量添加'}
                accept={'.csv'}
                ref={fileUploadRef}
                doImport={(params: FormData) => {
                    return importScriptApi(params);
                }}
                downloadTemplate={() => {
                    downloadTempFile(template, IMPORT_FILE_NAME);
                }}
                templateTips={<UploadDesc list={tipList}/>}
                onImportSuccess={(res) => {
                    message.success(`上传成功，本次上传${res.total}个稿件`);
                    onReload?.();
                }}
                onImportError={(err) => {
                    console.warn('[上传失败]', err);
                    // message.error('上传失败');
                }}
            />
        </>
    );
}
