import {FilterArticle} from '@meitu/rake';
import ManuscriptForm from './ManuscriptForm';
import ManuscriptFormAction from './ManuscriptFormAction';
import ManuscriptList from './ManuscriptList';
import ManuscriptEditor, {ManuscriptEditorRef} from './ManuscriptEditor';

import {forwardRef, useRef} from 'react';
import useManuscriptQuery, {initialFilterValues} from './useManuscriptQuery';
import {ManuscriptContext} from './context';

import {ScriptsFilterValues} from '@/types/talent/scripts';

export default forwardRef<HTMLDivElement>(function ManuscriptDetailWrapper(
    props,
    ref
) {
    /** 弹窗 */
    const manuscriptEditorRef = useRef<ManuscriptEditorRef>(null);
    /** 列表 */
    const {loading, list, total, queryParams, onChangeQueryParams, onReload} =
        useManuscriptQuery();

    return (
        <ManuscriptContext.Provider
            value={{
                manuscriptEditorRef
            }}
        >
            <FilterArticle<ScriptsFilterValues>
                title="稿件管理"
                extra={<ManuscriptFormAction onReload={onReload}/>}
                loading={loading}
                onChangeQueryParams={onChangeQueryParams}
                queryParams={queryParams}
                initialFilterValues={initialFilterValues}
                form={<ManuscriptForm/>}
                table={
                    <ManuscriptList
                        dataSource={list}
                        total={total}
                        onReload={onReload}
                    />
                }
            />
            <ManuscriptEditor ref={manuscriptEditorRef} onChange={() => onReload()}/>
        </ManuscriptContext.Provider>
    );
});
