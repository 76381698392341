import {action, computed, thunk} from 'easy-peasy';
import {EMPTY_ARRAY, getLocalStorageItem, setLocalStorageItem} from '@meitu/util';
import {generateLabelNames, getIndicatorFormatColumns} from '@/utils';
import {ScriptKocStoreModel} from '@/types/store/talent/scriptKoc';
import {fetchContentTypeApi, fetchMediaTypeApi, fetchScriptListApi} from '@/api/talent/script';
import {SCRIPT_KOC_CUSTORM_COLUMN_STORAGE_KEY} from '@/constants';
import {SCRIPT_KOC_LIST_COLUMN_OPTIONS} from '@/constants/talent/manuscripts';
import {ManuscriptsGroupsType} from "@/types";
import {fetchGroupOptionsApi} from "@/api/talent";
import {fetchTalentAppListApi} from "@/api";

const scriptKoc: ScriptKocStoreModel = {
    manuscript: EMPTY_ARRAY,
    total: 0,

    setManuscriptList: action((state, manuscript) => {
        state.manuscript = (manuscript?.list ?? EMPTY_ARRAY).map(
            ({labels, ...rest}) => ({
                ...rest,
                labels,
                labelNames: generateLabelNames(labels)
            })
        );
        state.total = manuscript?.total ?? 0;
    }),

    fetchManuscriptList: thunk(async (actions, payload) => {
        try {
            const manuscript = await fetchScriptListApi(payload);
            actions.setManuscriptList(manuscript);
        } catch (err) {
            actions.setManuscriptList(undefined);
        }
    }),

    /**
     * 选中的自定义展示列
     */
    customColumnKeys:
        getLocalStorageItem(SCRIPT_KOC_CUSTORM_COLUMN_STORAGE_KEY) ?? [],
    setCustomColumnKeys: action((state, values) => {
        state.customColumnKeys = values;
        setLocalStorageItem(SCRIPT_KOC_CUSTORM_COLUMN_STORAGE_KEY, values);
    }),

    /** 数据明细表头 */
    customColumns: computed(({customColumnKeys}) =>
        getIndicatorFormatColumns(
            customColumnKeys,
            SCRIPT_KOC_LIST_COLUMN_OPTIONS,
            false
        )
    ),

    /**
     * 达人投放应用列表
     */
    talentAppList: EMPTY_ARRAY,
    setTalentAppList: action((state, list) => {
        state.talentAppList = list ?? EMPTY_ARRAY;
    }),
    fetchTalentAppList: thunk(async (actions) => {
        try {
            const list = await fetchTalentAppListApi();
            actions.setTalentAppList(list);
        } catch (err) {
            actions.setTalentAppList(undefined);
        }
    }),

    /**
     * 代理商/标签
     */
    groupsOptionsMap: {
        [ManuscriptsGroupsType.Apps]: EMPTY_ARRAY,
        [ManuscriptsGroupsType.MediaTypes]: EMPTY_ARRAY,
        [ManuscriptsGroupsType.ContentTypes]: EMPTY_ARRAY,
        [ManuscriptsGroupsType.Labels]: EMPTY_ARRAY,
    },

    setGroupsOptions: action((state, {groupsType, list}) => {
        state.groupsOptionsMap[groupsType] = list ?? EMPTY_ARRAY;
    }),

    fetchGroupsOptions: thunk(async (actions, {groupsType}) => {
        try {
            const list = await fetchGroupOptionsApi(groupsType);
            actions.setGroupsOptions({
                groupsType,
                list
            });
        } catch (err) {
            actions.setGroupsOptions({
                groupsType,
                list: undefined
            });
        }
    }),


    /**
     * 获客媒体类型列表
     */
    mediaTypeList: EMPTY_ARRAY,
    setMediaTypeList: action((state, list) => {
        state.mediaTypeList = list ?? EMPTY_ARRAY;
    }),
    fetchMediaTypeList: thunk(async (actions) => {
        try {
            const list = await fetchMediaTypeApi();
            actions.setMediaTypeList(list);
        } catch (err) {
            actions.setMediaTypeList(undefined);
        }
    }),

    /**
     * 获客内容类型列表
     */
    contentTypeList: EMPTY_ARRAY,
    setContentTypeList: action((state, list) => {
        state.contentTypeList = list ?? EMPTY_ARRAY;
    }),
    fetchContentTypeList: thunk(async (actions) => {
        try {
            const list = await fetchContentTypeApi();
            actions.setContentTypeList(list);
        } catch (err) {
            actions.setContentTypeList(undefined);
        }
    }),

};

export default scriptKoc;
