import { useState } from 'react';
import {
  Alert,
  Button,
  ButtonProps,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Tooltip
} from 'antd';
import { InfoCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { DEFAULT_TABLE_FORM_COL } from '@/constants';
import { IndicatorOptionItem } from '@/types';
import { catchFormError } from '@meitu/rake';
import _ from 'lodash';

import styles from './index.module.less';
import { EMPTY_ARRAY } from '@meitu/util';

interface IndicatorEditorFormValues<Keys = string | number | symbol> {
  indicatorKeys: Keys[];
}

interface IndicatorEditorActionProps<Keys = string | number | symbol>
  extends ButtonProps {
  buttonLabel?: string;
  modalTitle?: string;
  icon?: React.ReactNode;
  required?: boolean;
  category?: boolean;
  indicatorOptions: IndicatorOptionItem[];
  indicatorKeys: Keys[];
  needQuery?: boolean;
  tips?: string;
  width?: number;
  onComfirm: (indicatorKeys: Keys[]) => void;
}

const renderIndicatorCheckbox = (indicatorOptions: IndicatorOptionItem[]) =>
  _.map(indicatorOptions, ({ title, tips, key, disabled }) => (
    <Col span={8} key={key as any}>
      <Checkbox value={key} disabled={disabled}>
        {title}
      </Checkbox>
      {tips ? (
        <Tooltip
          overlayClassName={styles.tipOverlay}
          placement="topLeft"
          arrowPointAtCenter
          title={tips}
        >
          <InfoCircleOutlined className={styles.tipIcon} />
        </Tooltip>
      ) : null}
    </Col>
  ));

/**
 * 自定义指标概况按钮和弹窗
 */
export default function IndicatorEditorAction<Keys = string | number | symbol>({
  buttonLabel = '自定义指标',
  modalTitle = '指标选择',
  icon = <SettingOutlined />,
  required = false,
  category = false,
  indicatorKeys,
  indicatorOptions,
  onComfirm,
  onClick,
  needQuery = false,
  tips,
  width = 630,
  ...restBtnProps
}: IndicatorEditorActionProps<Keys>) {
  const [form] = Form.useForm<IndicatorEditorFormValues<Keys>>();
  const [visible, setVisible] = useState(false);
  const [optionValue, setOptionValue] = useState<Array<any>>(EMPTY_ARRAY);

  const onOpen = () => {
    form.setFieldsValue({ indicatorKeys: indicatorKeys as any });
    setOptionValue(indicatorOptions);
    setVisible(true);
  };
  const onCheckAll = () => {
    form.setFieldsValue({
      indicatorKeys: _.map(optionValue, 'key') as any
    });
  };

  const onCancelCheck = () => {
    form.setFieldsValue({
      indicatorKeys: []
    });
  };

  const onSubmit = ({ indicatorKeys }: IndicatorEditorFormValues<Keys>) => {
    onComfirm?.(indicatorKeys);
    setVisible(false);
  };

  const onChange = (event: any) => {
    const v = event.target.value;
    indicatorOptions = indicatorOptions.filter((option) => {
      return _.includes(_.toLower(option.title), _.toLower(v));
    });
    setOptionValue(indicatorOptions);
  };

  return (
    <>
      <Button
        type="link"
        icon={icon}
        onClick={(event) => {
          event.stopPropagation();
          onClick?.(event);
          onOpen();
        }}
        {...restBtnProps}
      >
        {buttonLabel}
      </Button>
      <span
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Modal
          width={width}
          visible={visible}
          title={
            <Space size="large">
              {modalTitle}
              <Space>
                <Button
                  className={styles.checkAllBtn}
                  type="link"
                  size="small"
                  onClick={onCheckAll}
                >
                  选择全部
                </Button>
                <Button
                  className={styles.checkAllBtn}
                  type="link"
                  size="small"
                  onClick={onCancelCheck}
                >
                  取消选中
                </Button>
              </Space>
              {needQuery ? (
                <>
                  <Space>
                    <Input
                      allowClear
                      placeholder="请输入指标名称查询！"
                      onChange={onChange}
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                  </Space>
                </>
              ) : null}
            </Space>
          }
          maskClosable={false}
          destroyOnClose
          onOk={() =>
            form.validateFields().then(onSubmit).catch(catchFormError)
          }
          onCancel={() => setVisible(false)}
          afterClose={() => form.resetFields()}
        >
          {tips ? (
            <>
              <Space size="small">
                <Alert
                  banner
                  type="warning"
                  showIcon
                  className={styles.alert}
                  message={<span>{tips}</span>}
                />
              </Space>
              <br />
              <br />
            </>
          ) : null}
          <Form {...DEFAULT_TABLE_FORM_COL} form={form}>
            <Form.Item
              label=""
              name="indicatorKeys"
              rules={
                required
                  ? [{ required: true, message: '请至少选择一项' }]
                  : undefined
              }
            >
              <Checkbox.Group className={styles.checkWrap}>
                {category ? (
                  _.map(
                    _.groupBy(optionValue, 'category'),
                    (groupItem, cate) => (
                      <Row
                        gutter={[8, 8]}
                        className={styles.categoryWrap}
                        key={cate as any}
                      >
                        <Col span={24}>
                          <h4 className={styles.categoryTitle}>{cate}</h4>
                        </Col>
                        {renderIndicatorCheckbox(groupItem)}
                      </Row>
                    )
                  )
                ) : (
                  <Row gutter={[8, 8]}>
                    {renderIndicatorCheckbox(optionValue)}
                  </Row>
                )}
              </Checkbox.Group>
            </Form.Item>
          </Form>
        </Modal>
      </span>
    </>
  );
}
