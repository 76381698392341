import { Form, Row, Col } from 'antd';
import { FilterForm, FilterFormProps, TrimInput } from '@meitu/rake';
import GroupsFormAction from './GroupsFormAction';

import { useContext } from 'react';
import { GroupsContext } from './context';

import {ManuscriptGroupsListFilterValues} from "@/types/talent/scripts";

interface ManuscriptGroupsFormValuesProps
  extends Omit<FilterFormProps<ManuscriptGroupsListFilterValues>, 'children'> {}

export default function GroupsForm({
  ...restFilterFormProps
}: ManuscriptGroupsFormValuesProps) {
  const { groupsLabel } = useContext(GroupsContext);

  return (
    <Row wrap={false} gutter={48}>
      <Col flex="auto">
        <FilterForm
          {...restFilterFormProps}
          colProps={{ xxl: 8, lg: 12, sm: 24 }}
        >
          <Form.Item label={`${groupsLabel}名称`} name="name">
            <TrimInput
              maxLength={64}
              placeholder={`请输入${groupsLabel}名称`}
              allowClear
            />
          </Form.Item>
        </FilterForm>
      </Col>
      <Col flex="none">
        <GroupsFormAction />
      </Col>
    </Row>
  );
}
