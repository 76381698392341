import {action, thunk, computed} from 'easy-peasy';
import _ from 'lodash';
import {getAccountProfile} from '@/api';
import {PLATFORM_MAP} from '@/constants';

import {AccountStoreModel, AdType} from '@/types';
import {siderMenuItems} from "@/constants/sider";
import { getFistTreeNode } from '@/utils/menu';

const account: AccountStoreModel = {
    /** 账户信息 */
    profile: undefined,

    /** 应用 */
    apps: undefined,
    appOptions: computed(
        ({apps}) =>
            apps?.map(({id, appName, platform, appId}) => ({
                value: `${appId}-${platform}`,
                label: `${appName}-${PLATFORM_MAP.get(platform) ?? '-'}`
            })) ?? []
    ),

    /** 投放平台 */
    types: undefined,
    typeOptions: computed(
        ({types}) =>
            types?.map(({id, name}) => ({
                value: id,
                label: name
            })) ?? []
        /**
         * NOTE: 对外暂时不提供'未投放'
         */
        // .concat([{ value: 0, label: '未投放' }])
    ),

    setAccountProfile: action((state, accountProfile) => {
        state.apps = accountProfile?.apps;
        state.types = accountProfile?.types;
        state.profile = _.omit(accountProfile, ['apps', 'types']);

        state.roleAdTypes = Array.from(
            new Set(accountProfile?.appRoles.map((appRole) => appRole.adType))
        );
        if (state.roleAdTypes.includes(AdType.CHANNEL)) {
            state.roleAdType = AdType.CHANNEL;
        } else if (state.roleAdTypes.includes(AdType.TALENT)) {
            state.roleAdType = AdType.TALENT;
        }
    }),

    fetchAccountProfile: thunk(async (actions) => {
        try {
            const accountProfile = await getAccountProfile();
            actions.setAccountProfile(accountProfile);
            return accountProfile;
        } catch (error) {
            actions.setAccountProfile(undefined);
            return Promise.reject(error);
        }
    }),

    roleAdTypes: [],
    /** 投放方式 渠道投放/达人投放 */
    roleAdType: undefined,

    setRoleAdType: action((state, roleAdType) => {
        state.roleAdType = roleAdType;
    }),


    /** 当前选中项目可访问主页 */
    currentHomeRoute: computed(({roleAdType}) => {
        return roleAdType === AdType.CHANNEL ? "/channel/activate" : "/talent/script";
    }),

    /**
     * 当前可访问的菜单树
     * NOTE: 不包含页面中自定义的第三层选项卡菜单
     */
    authMenuTree: computed(
        ({
             roleAdType
         }) => {
            // 设置按钮 关，菜单树按照投放方式过滤，并且按照应用、客户端、平台权限限制处理
            return _.filter(siderMenuItems, {
                adType: roleAdType
            });
        }
    ),

    /** 当前可访问的首个菜单 */
    authMenuTreeFirstRoute: computed(({ authMenuTree }) => {
        return getFistTreeNode(authMenuTree)?.route ?? '';
    }),

};

export default account;
