import {IRakeLayoutSiderMenuItem} from "@meitu/rake";

export * from './account';
export * from './app';
export * from './channel';
export * from './activate';
export * from './indicator';
export * from './talent';

export * from './store';
export * from './api';


export interface SiderAuthMenuItem extends IRakeLayoutSiderMenuItem {
    adType?: string;
    children?: SiderAuthMenuItem[];
}


/**
 * 模态框类型
 */
export enum EditorType {
    /** 添加 */
    Add,

    /** 编辑 */
    Edit,

    /** 复制 */
    COPY,

    /** 展示 */
    SHOW
}