import React, {forwardRef, useState} from 'react';
import {Card} from 'antd';
import LoginForm from './LoginForm';
import LoginEmailVerifyForm from './LoginEmailVerifyForm';
import LoginBg from './LoginBg';

import styles from './index.module.less';

export default forwardRef<HTMLDivElement>(function Login(props, ref) {
    const [loginAccount, setLoginAccount] = useState<string>();


    return (
        <div className={styles.loginPage}>
            <Card title={`欢迎登录${process.env.REACT_APP_TITLE}`} bordered={false}>
                {loginAccount ? <LoginEmailVerifyForm account={loginAccount}/> :
                    <LoginForm onLoginChecked={setLoginAccount}/>}
            </Card>
            {/* 背景效果 */}
            <LoginBg/>
        </div>
    );
});
