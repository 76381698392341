import { ConfirmButton } from '@/components';

import _ from 'lodash';
import { deleteScriptApi } from '@/api/talent/script';
import { Manuscript } from '@/types/talent/scripts';

interface ScriptBatchDeleteProps {
  list?: Manuscript[];
  onSuccess?: () => void;
}

/**
 * 渠道管理批量删除按钮+弹窗
 */
export default function ManuscriptBatchDelete({
  list,
  onSuccess
}: ScriptBatchDeleteProps) {
  return (
    <ConfirmButton
      actionName="批量删除"
      label="稿件"
      type="default"
      size="middle"
      disabled={_.isEmpty(list)}
      content={`是否删除${_.map(list?.slice(0, 2), 'link').join('、')}${
        list && list?.length > 2 ? '等共计' : '共'
      }${list?.length}个稿件？`}
      onConfirm={() => deleteScriptApi(_.map(list, 'id'))}
      onSuccess={onSuccess}
    />
  );
}
