import { useState } from 'react';

interface TruncatedTextProps {
  url: string;
  maxLength?: number;
}

/**
 * 文本缩略组件
 */
export default function TruncatedText({
  url,
  maxLength = 20
}: TruncatedTextProps) {
  const [isTruncated, setIsTruncated] = useState(true);

  const displayUrl =
    isTruncated && url.length > maxLength
      ? `${url.slice(0, maxLength)}...`
      : url;

  return (
    <span style={{ cursor: 'pointer', color: 'blue' }} title={url}>
      {displayUrl}
    </span>
  );
}
