import {useEffect, useState} from 'react';
import {Transfer, TransferProps, Spin} from 'antd';


import styles from './index.module.less';
import {ManuscriptGroupsItem, ManuscriptsGroupsType} from "@/types/talent/scripts";
import {fetchRelateScriptListApi} from "@/api/talent/script";

type ItemType = ManuscriptGroupsItem;
type ValueType = ItemType['id'];

interface ManuscriptGroupsTransferProps
    extends Omit<TransferProps<ItemType>, 'dataSource' | 'listStyle'> {
    /** 关联的渠道分组类型 */
    groupsType: ManuscriptsGroupsType;
    /** 关联的分组类型id */
    propertyId?: number;
    /** 选中的渠道id */
    value?: ValueType[];
    afterFetch?: (checkedValues: ValueType[], list: ManuscriptGroupsItem[]) => void;
}

/**
 * 渠道穿梭框选择器。用于设置渠道分组关联的渠道。
 *
 * 待选区选项：
 * 1. 代理商和 ACID 单一分组，已有分组的渠道不再待选区展示。
 * 2. 标签支持一对多，待选区展示所有渠道。
 */
export default function RelateManuscriptTransfer({
                                                     groupsType, propertyId,
                                                     afterFetch,
                                                     value,
                                                     ...restProps
                                                 }: ManuscriptGroupsTransferProps) {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<ItemType[]>([]);

    const fetchData = (type: ManuscriptsGroupsType, id?: number) => {
        setLoading(true);
        fetchRelateScriptListApi(type, id)
            .then((list) => {
                const checkedValues: ValueType[] = [];
                list.forEach(({id, checked}) => {
                    if (checked) {
                        checkedValues.push(id);
                    }
                });
                setOptions(list);
                afterFetch?.(checkedValues, list);
            })
            .finally(() => setLoading(false));
    };

    useEffect(
        () => {
            fetchData(groupsType, propertyId);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [groupsType, propertyId]
    );

    const getLabel = (title: string, link: string) => {
        return title ? title : link;
    }

    return (
        <Spin spinning={loading}>
            <Transfer<ItemType>
                {...restProps}
                className={styles.transferSmall}
                listStyle={{
                    width: 191,
                    height: 275
                }}
                showSearch
                locale={{searchPlaceholder: '请输入稿件名称或标识'}}
                dataSource={options}
                rowKey={({id}) => id + ''}
                render={({title, link}) => getLabel(title, link)}
                targetKeys={value?.map((v) => v + '')}
                operations={['选择', '删除']}
                footer={({direction}) =>
                    direction === 'left' ? '待选列表' : '已选列表'
                }
            />
        </Spin>
    );
}
