import { IndicatorOptionItem } from '.';

/**
 * 稿件互动明细维度
 */
export enum ManuscriptsDimensionType {
  /** 约稿时间 */
  AgreedTimeStr = 'agreedTimeStr',
  /** Gap Day */
  GapDays = 'gapDays',
  /** 稿件审核链接 */
  AuditLink = 'auditLink',
  /** 内容形式 */
  ContentTypeDesc = 'contentTypeDesc',
  /** 达人名称 */
  ExpertName = 'expertName',
  /** 达人微信名称 */
  ExpertWechatName = 'expertWechatName',
  /** uid */
  ExpertUid = 'expertUid',
  /** 供应商 */
  Supplier = 'supplierName',
  /** 渠道标签 */
  Labels = 'labelNames',
  APP = 'appName',
  CREATE_USER = 'createUserName',
  MediaTypeDesc = 'mediaTypeDesc'
}

export type ManuscriptsDimensionItem = {
  [Key in ManuscriptsDimensionType]?: string;
};

/** 稿件互动明细表头配置对象 */
export type ManuscriptsInfo = ManuscriptsDimensionItem & {
  [key: string]: string | number | boolean | number[] | any; // 字符串或数字索引签名
};

/**
 * 稿件互动明细维度选项
 */
export const ManuscriptsDimensionOptionMap: Record<
    ManuscriptsDimensionType,
    IndicatorOptionItem
    > = {
  [ManuscriptsDimensionType.AgreedTimeStr]: {
    title: '约稿时间',
    columnWidth: 110
  },
  [ManuscriptsDimensionType.GapDays]: { title: 'Gap Day', columnWidth: 70 },
  [ManuscriptsDimensionType.AuditLink]: {
    title: '稿件审核链接',
    columnWidth: 100
  },
  [ManuscriptsDimensionType.ContentTypeDesc]: {
    title: '内容形式',
    columnWidth: 90
  },
  [ManuscriptsDimensionType.ExpertName]: {
    title: '达人名称',
    columnWidth: 100
  },
  [ManuscriptsDimensionType.ExpertWechatName]: {
    title: '达人微信名称',
    columnWidth: 100
  },
  [ManuscriptsDimensionType.ExpertUid]: { title: 'uid', columnWidth: 100 },
  [ManuscriptsDimensionType.Supplier]: { title: '供应商', columnWidth: 70 },
  [ManuscriptsDimensionType.Labels]: { title: '标签', columnWidth: 120 },
  [ManuscriptsDimensionType.CREATE_USER]: { title: '添加人', columnWidth: 70 },
  [ManuscriptsDimensionType.APP]: { title: '应用', columnWidth: 70 },
  [ManuscriptsDimensionType.MediaTypeDesc]: {
    title: '媒体类型',
    columnWidth: 90
  }
};
