import { useState } from 'react';
import { useStoreActions } from '@/store';
import {
  useFilterableListQueryParams,
  useIsMountedRef,
  DEFAULT_PAGE,
  ListQueryParams
} from '@meitu/rake';
import { fetchScriptPropertyListApi } from '@/api/talent/property';

import {
  ManuscriptsGroupsListItem,
  ManuscriptsGroupsType,
  ManuscriptGroupsListFilterValues
} from '@/types/talent/scripts';

/**
 * 筛选表单默认值
 */
export const initialFilterValues: ManuscriptGroupsListFilterValues =
  Object.freeze({
    name: undefined
  });

/**
 * 数据请求
 */
const useGroupsFetch = (groupsType: ManuscriptsGroupsType) => {
  const isMounted = useIsMountedRef();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<ManuscriptsGroupsListItem[]>([]);
  const [total, setTotal] = useState<number>(0);

  /** 发送请求并筛选数据 */
  const onFetchData = ({
    filters = initialFilterValues,
    page = DEFAULT_PAGE
  }: ListQueryParams<ManuscriptGroupsListFilterValues> = {}) => {
    setLoading(true);
    fetchScriptPropertyListApi(groupsType, page, filters)
      .then(({ list, total }) => {
        if (!isMounted.current) return;
        setList(list);
        setTotal(total);
      })
      .finally(() => {
        if (!isMounted.current) return;
        setLoading(false);
      });
  };
  return { loading, list, total, onFetchData };
};

const useGroupsQuery = (groupsType: ManuscriptsGroupsType) => {
  const [queryParams, setQueryParams] =
    useFilterableListQueryParams<ManuscriptGroupsListFilterValues>();
  const { loading, list, total, onFetchData } = useGroupsFetch(groupsType);

  /** 重新加载（使用原本的查询数据） */
  const onReload = () => {
    setQueryParams(queryParams);
    // 请求列表数据
    onFetchData(queryParams);
  };

  /** 更新查询参数（筛选、分页、排序）*/
  const onChangeQueryParams = (
    newQueryParams: ListQueryParams<ManuscriptGroupsListFilterValues>
  ) => {
    setQueryParams(newQueryParams);
    onFetchData(newQueryParams);
  };


  return {
    loading,
    list,
    total,
    queryParams,
    onChangeQueryParams,
    onReload
  };
};

export default useGroupsQuery;
