import { useStoreState } from '@/store';

/**
 * 获取账户信息
 */
export function useAccountProfile() {
  const profile = useStoreState((state) => state.account.profile);
  return {
    name: profile?.username,
    email: profile?.email,
    id: profile?.id
  } as const;
}
