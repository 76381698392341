import {Form, Button, Typography, message} from 'antd';
import {UserOutlined, RobotOutlined} from '@ant-design/icons';
import {TrimInput} from '@meitu/rake';

import {useEffect, useState} from 'react';

import {useHistory} from 'react-router-dom';
import {loginWithEmailCode, verifyEmailCode} from '@/api';

import {LoginFormEmailCodeValues} from '@/types';
import {
    VALID_CODE_REGEX,
    VALID_CODE_REGEX_MSG
} from '@/constants';

import styles from './LoginForm.module.less';

const sendSeconds = 60; //邮箱验证码发送倒计时时间（单位：秒）

export default function LoginForm(props: { account: string; }) {
    const history = useHistory();
    const [form] = Form.useForm<LoginFormEmailCodeValues>();
    const [loginLoading, setLoginLoading] = useState<boolean>(false);
    const [fetchCodeLoading, setFetchCodeLoading] = useState<boolean>(false);
    const [verifyCodeBtn, setVerifyCodeBtn] = useState({name: '获取验证码', disabled: false});
    let sendVerifyEmailCodeInterval: NodeJS.Timeout;    //邮箱验证码已发送倒计时器

    const onFinish = (values: LoginFormEmailCodeValues) => {
        setLoginLoading(true);
        loginWithEmailCode(values)
            .then(() => {
                setLoginLoading(false);
                history.replace('/');
            })
            .catch(() => {
                setLoginLoading(false);
            });
    };

    const fetchEmailVerifyCode = () => {
        form.setFieldsValue({code: ''});
        setFetchCodeLoading(true);

        verifyEmailCode()
            .then(({data: minutesValid}) => {
                let verifyEmailCodeSendSeconds = sendSeconds;
                sendVerifyEmailCodeInterval = setInterval(() => {
                    if (verifyEmailCodeSendSeconds > 0) {
                        --verifyEmailCodeSendSeconds;
                        setVerifyCodeBtn({name: `已发送（${verifyEmailCodeSendSeconds}s）`, disabled: true})
                    } else {
                        clearInterval(sendVerifyEmailCodeInterval);
                        verifyEmailCodeSendSeconds = sendSeconds;
                        setVerifyCodeBtn({name: '获取验证码', disabled: false})
                    }
                }, 1000);
                setFetchCodeLoading(false);
                message.success(`验证码发送成功，${minutesValid}分钟内有效`);
            }).catch(() => {
            setFetchCodeLoading(false);
            clearInterval(sendVerifyEmailCodeInterval);
        });
    };

    useEffect(() => {
        return () => {
            if (sendVerifyEmailCodeInterval !== undefined) {
                clearInterval(sendVerifyEmailCodeInterval);
            }
        };
    }, []);

    return (
        <div>
            <Form form={form} size="large" autoComplete="off" onFinish={onFinish}>
                <Form.Item initialValue={props.account}>
                    <TrimInput
                        prefix={<UserOutlined/>}
                        maxLength={64}
                        disabled
                        value={props.account}
                        placeholder="请输入登录账号"
                    />
                </Form.Item>
                <Form.Item className={styles.verifyCode}>
                    <Form.Item
                        noStyle
                        name="code"
                        validateTrigger="onBlur"
                        rules={[
                            {
                                pattern: VALID_CODE_REGEX,
                                message: VALID_CODE_REGEX_MSG
                            },
                            {required: true, message: '请输入邮箱验证码'}
                        ]}
                    >
                        <TrimInput
                            prefix={<RobotOutlined/>}
                            placeholder="请输入邮箱验证码"
                        />
                    </Form.Item>
                    <Button disabled={verifyCodeBtn.disabled}
                            loading={fetchCodeLoading}
                            onClick={fetchEmailVerifyCode}>{verifyCodeBtn.name}</Button>
                </Form.Item>
                <Form.Item noStyle>
                    <Button type="primary" block htmlType="submit" loading={loginLoading}>
                        登录
                    </Button>
                    <Typography.Text type="warning">
                        tips：首次访问该系统需联系您的对接人进行权限开通
                    </Typography.Text>
                </Form.Item>
            </Form>
        </div>
    );
}
