import {createInstance} from '@/api/utils';
import {ListApiResponse, OptionsApiResponse, PagingListApiResponse} from '@/types';
import {
  ManuscriptGroupsFormValues,
  ManuscriptGroupsListFilterValues,
  ManuscriptsGroupsListItem,
  ManuscriptsGroupsType
} from '@/types/talent/scripts';
import {PaginateParams} from "@meitu/rake";
import _ from "lodash";

const baseURL = '/api/script/property';
const instance = createInstance({ baseURL });



export function fetchGroupOptionsApi(type: ManuscriptsGroupsType) {
  return instance
    .get<void, PagingListApiResponse<ManuscriptsGroupsListItem>>(
      `/${type}/option`
    )
    .then(({ list }) => {
      return list;
    });
}

export function fetchScriptPropertyListApi(
    type: ManuscriptsGroupsType,
    page: PaginateParams,
    filters?: ManuscriptGroupsListFilterValues
) {
  return instance
      .get<void, PagingListApiResponse<ManuscriptsGroupsListItem>>(`/${type}`, {
        params: {
          ...page,
          name: filters?.name
        }
      })
      .then(({ list, total, pageNum, pageSize }) => {
        return {
          list: _.map(list, (record, index) => {
            return {
              ...record,
              index: (pageNum - 1) * pageSize + index + 1
            };
          }),
          total
        };
      });
}



export function deleteScriptPropertyApi(
    type: ManuscriptsGroupsType,
    id: ManuscriptsGroupsListItem['id']
) {
  return instance.delete<void, void>(`/${type}/${id}`);
}



export function addScriptPropertyApi(
    type: ManuscriptsGroupsType,
    params: ManuscriptGroupsFormValues
) {
  return instance.post<void, void>(`/${type}`, {
    ...params
  });
}


export function updateScriptPropertyApi(
    type: ManuscriptsGroupsType,
    id: number,
    params: ManuscriptGroupsFormValues
) {
  return instance.post<void, void>(`/${type}/${id}`, {
    ...params
  });
}

