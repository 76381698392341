import {Tooltip} from 'antd';
import {IndicatorEditorAction} from '@/components';
import {ManuscriptsDimensionType} from '@/types/indicator/manuscripts';
import {SCRIPT_KOC_LIST_COLUMN_OPTIONS} from '@/constants/talent/manuscripts';

interface ScriptKocEditorActionProps {
  indicatorKeys: ManuscriptsDimensionType[];
  onConfirm: (indicatorKeys: ManuscriptsDimensionType[]) => void;
}

/**
 * 稿件互动明细配置按钮+弹窗
 */
export default function ScriptKocColumnEditorAction({
  indicatorKeys,
  onConfirm
}: ScriptKocEditorActionProps) {
  return (
    <Tooltip title="表头配置">
      <IndicatorEditorAction
        buttonLabel="表头配置"
        modalTitle="表头配置"
        required={false}
        indicatorOptions={SCRIPT_KOC_LIST_COLUMN_OPTIONS}
        indicatorKeys={indicatorKeys}
        onComfirm={onConfirm}
      />
    </Tooltip>
  );
}
