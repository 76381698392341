import {PaginateParams} from "@meitu/rake";


/** 渠道标签信息 */
export interface GroupLabel {
    id: number;
    name: string;
}


/**
 * 数据概况-渠道信息过滤条件
 */
export interface ScriptsFilterValues {
    // 应用
    appIds?: number[];
    // 稿件链接
    link?: string;
    // 媒体类型
    mediaTypeIds?: string[];
    // 内容类型
    contentTypeIds?: string[];
    // 标签
    labelIds?: number[];
    // 达人信息
    expertInfo?: string;
    // uid
    expertUid?: string;
    //添加人
    createUserIds?: number[];
}

/**
 * 代理商/标签列表行数据
 */
export interface ManuscriptsGroupsListItem {
    /** 代理商/ACID/标签id */
    id: number;
    /** 代理商/ACID/标签名称 */
    name: string;
    /** 添加人（邮箱） */
    createUsername: string;
    /** 添加时间 */
    createTime: number;
}

/**
 * 稿件分组管理（供应商/标签）列表筛选表单
 */
export interface ManuscriptGroupsListFilterValues {
    /** 供应商/标签名称 */
    name?: string;
}

/**
 * 稿件互动明细——复数形式
 */
export enum ManuscriptsGroupsType {
    /** 应用 */
    Apps = 'apps',
    /** 媒体类型 */
    MediaTypes = 'mediaTypes',
    /** 内容类型 */
    ContentTypes = 'contentTypes',
    /** 渠道标签。允许一对多关系 */
    Labels = 'labels',
}

/**
 * 数据概况-列表请求参数
 */
export interface ManuscriptsQueryParams {
    page: PaginateParams;
    filters?: ScriptsFilterValues;
}


export interface ManuscriptGroupsFormValues {
    name: string;
    scriptIds: number[];
}


/** 渠道分组信息 */
export interface ManuscriptGroupInfo {
    supplierId: number;
    supplierName: string;
    labels: GroupLabel[];
    /* 标签名称。前端添加的数据。*/
    labelNames?: string;
}

export type Manuscript = ManuscriptGroupInfo & {
    id: number;
    link: string;
    auditLink: string;
    mediaType: string;
    mediaTypeDesc: string;
    contentType: string;
    contentTypeDesc: string;
    agreedTime: number;
    agreedTimeStr: string;
    expertName: string;
    expertWechatName: string;
    expertUid: string;
    appId: number;
    appName: string;
    strikePrice: number;
    createUserId: number;
    publishDate: number;
    lastUpdateDate: number;
    /** 创建时间 */
    createTime: number;
    /** 更新时间 */
    updateTime: number;
    [key: string]: string | number | boolean | number[] | any; // 字符串或数字索引签名
};

export interface ManuscriptFormValues
    extends Partial<Pick<Manuscript,
        | 'appId'
        | 'link'
        | 'auditLink'
        | 'mediaType'
        | 'contentType'
        | 'agreedTime'
        | 'expertName'
        | 'expertWechatName'
        | 'expertUid'
        | 'supplierId'
        | 'strikePrice'>> {
    /** 渠道标签id */
    labelIds?: number[];
}

/** 关联某渠道分组的渠道标识列表、表单 */
export interface ManuscriptGroupsItem
    extends Pick<Manuscript, 'id' | 'title' | 'link'> {
    /** 是否选中 */
    checked: boolean;
}
