import { Modal, Upload, Table, Divider, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { TemplateDownload, TemplateDownloadProps } from './TemplateDownload';

import { forwardRef, useImperativeHandle, useState, ReactNode } from 'react';
import _ from 'lodash';
import { EMPTY_ARRAY } from '@meitu/util';
import { generateHash } from '@/utils';

export { default as UploadDesc } from './UploadDesc';
export * from './UploadDesc';

enum FileStatus {
  'empty',
  'uploaded',
  'parseded',
  'imported'
}

/* 错误日志列表 */
function ErrorLogList({ logs }: { logs: string[] }) {
  return (
    <Table
      dataSource={_.map(logs, (log) => ({
        log
      }))}
      rowKey={generateHash}
      size="small"
    >
      <Table.Column title="错误日志" dataIndex="log" />
    </Table>
  );
}

export interface UploadModalRef {
  open(): void;
}

export interface UploadModalProps extends Partial<TemplateDownloadProps> {
  /** 上传弹窗标题 */
  title: string;
  /** 上传文件接受的类型 */
  accept?: string;
  /** 下载模版 */
  downloadTemplate?: Function;
  templateTips?: ReactNode | string;
  /** 文件解析 */
  doParse?(params: FormData): Promise<any>;
  onParseSuccess?: (list?: any) => void;
  onParseError?: (list?: any) => void;
  /** 文件上传 */
  doImport(params: FormData): Promise<any>;
  onImportSuccess?: (list?: any) => void;
  onImportError?: (list?: any) => void;
}

export default forwardRef<UploadModalRef, UploadModalProps>(
  function UploadModal(
    {
      title,
      accept,
      downloadTemplate,
      templateTips,
      doParse,
      onParseSuccess,
      onParseError,
      doImport,
      onImportSuccess,
      onImportError
    },
    ref
  ) {
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState<FileStatus>(FileStatus.empty);

    const [submitting, setSubmitting] = useState(false);
    const [fileList, setFileList] = useState<Array<any>>(EMPTY_ARRAY);
    const [file, setFile] = useState<File | null>(null);
    const [errorLogs, setErrorLogs] = useState(EMPTY_ARRAY);

    const resetData = () => {
      setStatus(FileStatus.empty);
      setVisible(false);
      setFileList(EMPTY_ARRAY);
      setFile(null);
      setErrorLogs(EMPTY_ARRAY);
    };

    const onChange = (info: any) => {
      if (info.file.status === 'removed') {
        setFile(null);
        setStatus(FileStatus.empty);
      } else {
        setFile(info.file);
        setStatus(doParse ? FileStatus.imported : FileStatus.parseded);
      }
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      setFileList(fileList);
      setErrorLogs(EMPTY_ARRAY);
    };

    const parseFile = async () => {
      if (!doParse) {
        return;
      }
      if (_.isNull(file)) return message.error('请先上传文件');
      setSubmitting(true);
      let params = new FormData();
      params.append('file', file);
      try {
        const res = await doParse(params);
        if (res?.length) {
          message.error('文件解析失败，异常数据详见错误日志');
          setErrorLogs(res);
          onParseError?.(res);
        } else {
          message.success('文件解析成功');
          setStatus(FileStatus.parseded);
          onParseSuccess?.(res);
        }
      } finally {
        setSubmitting(false);
      }
    };

    const importFile = async () => {
      if (_.isNull(file)) return message.error('请先上传文件');
      setSubmitting(true);
      let params = new FormData();
      params.append('file', file);
      try {
        const res = await doImport(params);
        if (res?.list?.length) {
          message.error('导入失败，异常数据详见错误日志');
          setErrorLogs(res.list);
          onImportError?.(res);
        } else {
          resetData();
          onImportSuccess?.(res);
        }
      } catch (err) {
        onImportError?.(err);
      } finally {
        setSubmitting(false);
      }
    };

    useImperativeHandle(ref, () => ({
      open: () => {
        setVisible(true);
      }
    }));

    return (
      <Modal
        width={600}
        visible={visible}
        title={
          <>
            {title}
            {/* 模板下载 */}
            {downloadTemplate && (
              <TemplateDownload
                downloadTemplate={downloadTemplate}
                templateTips={templateTips}
              />
            )}
          </>
        }
        maskClosable={false}
        okButtonProps={{
          loading: submitting,
          disabled: status === FileStatus.empty
        }}
        destroyOnClose
        okText={status === FileStatus.parseded || !doParse ? '提交' : '解析'}
        onOk={() =>
          status === FileStatus.parseded || !doParse
            ? importFile()
            : parseFile()
        }
        onCancel={resetData}
      >
        {/* 文件上传 */}
        <Upload.Dragger
          fileList={fileList}
          accept={accept}
          name="file"
          beforeUpload={() => {
            return false;
          }}
          onChange={onChange}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
          {accept ? (
            <p className="ant-upload-hint">支持扩展名: {accept}</p>
          ) : null}
        </Upload.Dragger>

        {/* 错误日志 */}
        {!_.isEmpty(errorLogs) && (
          <>
            <Divider dashed />
            <ErrorLogList logs={errorLogs} />
          </>
        )}
      </Modal>
    );
  }
);
