import { Radio, RadioChangeEvent } from 'antd';
import { AD_TYPE_LIST, AD_TYPE_MAP, AdType } from '@/types';
import { useStoreActions, useStoreState } from '@/store';
import { useHistory } from 'react-router';
import { useEffect } from 'react';

/**
 * 投放方式：渠道投放/达人投放
 * @param props
 * @constructor
 */
export default function PrimaryMenu() {
  const history = useHistory();
  const roleAdTypes = useStoreState((state) => state.account.roleAdTypes);

  const roleAdType = useStoreState((state) => state.account.roleAdType);

  const setRoleAdType = useStoreActions((state) => state.account.setRoleAdType);

  const options = [] as { label: string; value: string }[];

  AD_TYPE_MAP.forEach((value, label) =>
    options.push({ value: value, label: label })
  );

  const hasChannelAndTalent = AD_TYPE_LIST.every((adType) =>
    roleAdTypes.includes(adType)
  );

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setRoleAdType(value);
  };
  const currentHomeRoute = useStoreState(
      (state) => state.account.currentHomeRoute
  );

  // 切换投放方式，重新选择菜单栏的应用，同时跳转对应投放方式的首个菜单
  // 使用useEffect来监听myState的变化（可选）
  useEffect(() => {

    // 这里的代码会在myState更新后执行
    history.push(currentHomeRoute);
  }, [roleAdType]); // 依赖项数组，确保只在myState变化时执行effect

  return hasChannelAndTalent ? (
    <Radio.Group
      onChange={onChange}
      value={roleAdType}
      optionType="button"
      buttonStyle="solid"
    >
      <Radio.Button value={AdType.CHANNEL} style={{ marginRight: '20px' }}>
        {AD_TYPE_MAP.get(AdType.CHANNEL)}
      </Radio.Button>
      <Radio.Button value={AdType.TALENT} style={{ marginRight: '20px' }}>
        {AD_TYPE_MAP.get(AdType.TALENT)}
      </Radio.Button>
    </Radio.Group>
  ) : null;
}
